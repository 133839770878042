import React, { useEffect, useState } from 'react';
import Serverimage from '../components/Serverimage';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import { FaChevronRight } from 'react-icons/fa6';
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

const Course = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://fpaindia.in/fpa_india_admin/public/api/courses');
        setData(response.data); // Assuming the API response is in JSON format
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function
    fetchData();
  }, []); // Empty

  return (
    <>
      <Header />

      <div
        className="course_page_bg breadcumb-wrapper background-image shape-mockup-wrap course_page_main_top_hight"
        data-overlay="title"
        data-opacity="8"
        style={{ height: '460px' }}>
        <div className="breadcumb-shape background-image"></div>
        <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
          <img
            src={img_1}
            alt="shape"
          />
        </div>
        <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
          <img
            src={img_2}
            alt="shape"
          />
        </div>
        <Container style={{ height: '100%' }}>
          <Row style={{ height: '100%' }}>
            <Col sm={12}>
              <div className="breadcumb-content text-center">
                <h1 className="breadcumb-title">Our Course</h1>
                <ul className="breadcumb-menu">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <FaChevronRight />
                  </li>
                  <li>Our Course</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="space">
        <Container>
          <Row className="gy-40 filter-active">
            {data.map((item) => (
              <Col
                sm={4}
                className="filter-item cat1 cat3  mb-30">
                <div className="video-course">
                  <div className="course-img">
                    <Serverimage text={item.user_image} />
                    <NavLink to={'/Course/' + item.slug}>
                        {item.heading}
                      </NavLink>
                  </div>
                  <div className="course-content">
                    <h3 className="course-title">
                      <NavLink to={'/Course/' + item.slug}>
                        {item.heading}
                      </NavLink>
                    </h3>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Course;
