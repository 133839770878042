import React, { useEffect, useState } from 'react';
import Image from '../components/Image';
import Serverimage from '../components/Serverimage';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";
import { FaClock } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import axios from 'axios';



const Blog = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      // Function to fetch data from the API
      const fetchData = async () => {
        try {
          const response = await axios.get('https://fpaindia.in/fpa_india_admin/public/api/blog');
          setData(response.data); // Assuming the API response is in JSON format
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      // Call the function
      fetchData();
    }, []); // Empty
  return (
    <>
     <Header/>
   
   <div className="blog_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
   <div className="breadcumb-shape background-image"></div>
   <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
       <img src={img_1} alt="shape"/></div>
   <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
       <img src={img_2} alt="shape"/></div>
       <Container style={{height:'100%'}}>
           <Row style={{height:'100%'}}>
               <Col sm={12}>
               <div className="breadcumb-content text-center">
           <h1 className="breadcumb-title">Blogs Post</h1>
           <ul className="breadcumb-menu">
               <li><NavLink  to="/">Home</NavLink></li>
               <li><FaChevronRight /></li>
               <li>Blog</li>
           </ul>
       </div>
               </Col>
           </Row>
       </Container>
       </div>
       <section className="th-blog-wrapper space-top space-extra2-bottom arrow-wrap">
        <Container>
            <Row className="gx-40">
  {data.map((item) => (
    <Col sm={6}>
    <div key={item.id} className="th-blog blog-single has-post-thumbnail">
      <div className="blog-img">
        <NavLink to={'/Blog/' + item.slug}>
          <Serverimage text={item.user_image} />
        </NavLink>
      </div>
      <div className="blog-content">
        <div className="blog-meta">
          <NavLink className="author" to="blog.html">
            <FaUser />BY FPA INDIA
          </NavLink>{" "}
          <NavLink to={'/Blog/' + item.slug}>
            <FaClock />{item.blog_date}
          </NavLink>
        </div>
        <h2 className="blog-title">
          <NavLink to={'/Blog/' + item.slug}>{item.heading}</NavLink>
        </h2>
        <NavLink to={'/Blog/' + item.slug} className="link-btn">
          Read More Details
          <i className="fas fa-arrow-right ms-2"></i>
        </NavLink>
      </div>
    </div>
    </Col>
  ))}
            </Row>
        </Container>
</section>
<Footer/>
    </>
  )
}

export default Blog