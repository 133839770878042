import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../header/header';
import Footer from '../footer/Footer';
import img_1 from '../../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';

const Privacypolicy = () => {
  return (
    <>
    <Header />

<div
  className="Privacypolicy_page_bg breadcumb-wrapper background-image shape-mockup-wrap"
  data-overlay="title"
  data-opacity="8"
  style={{ height: '460px' }}>
  <div className="breadcumb-shape background-image"></div>
  <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
    <img
      src={img_1}
      alt="shape"
    />
  </div>
  <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
    <img
      src={img_2}
      alt="shape"
    />
  </div>
  <Container style={{ height: '100%' }}>
    <Row style={{ height: '100%' }}>
      <Col sm={12}>
        <div className="breadcumb-content text-center">
          <h1 className="breadcumb-title">FPA INDIA Privacy Policy</h1>
          <ul className="breadcumb-menu">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <FaChevronRight />
            </li>
            <li>FPA INDIA Privacy Policy</li>
          </ul>
        </div>
      </Col>
    </Row>
  </Container>
</div>
<div className="space">
<Container>
        <Row>
            <Col sm={12}>
            <div className="main-content-abot-page">
    <h4>FPA INDIA Privacy Policy</h4>
    <span>Last Updated: January 2023</span>
    <h4>Introduction</h4>
    <p>
        FPA INDIA® (Institute of Management Accountants) is the association of accountants and financial professionals in business. Provided below is our Privacy Policy to explain how we collect, use, disclose, transfer, and store your personal data. We use your personal data to better serve you by providing an efficient, meaningful, and customized experience. When you use FPA INDIA products and services, you agree to accept our policy, as well as our agreements. You can manage your information and privacy settings by visiting your FPA INDIA profile at myFPA INDIA.
    </p>
    <p>
        We encourage you to read this Policy before using this website(s) and providing your personal data to us. FPA INDIA knows that you care about how your information is used and shared and believe in protecting your privacy and the confidentiality of your Personal Information. Your use of our websites signifies that you understand and agree with all practices and terms of this Policy.
    </p>
    
    <h4>What Information Does FPA INDIA Collect?</h4>
    <p>
        FPA INDIA collects data from members and customers with the intention of providing the best level of service. It is our overall policy to collect only personally identifiable information that you knowingly provide. The types of personal information we collect may include your name, postal address, email address, phone number, gender, date of birth, education and employment information, and professional designations achieved. We may collect additional personal information from you on occasion as needs arise. Any personal data you provide will enable us to keep you informed, process your requests, or offer services to you with respect to our programs, products, and services.
    </p>
    <p>
    FPA INDIA always strives to use in-house and/or standard marketing analytic tools within our platforms to better serve our customers. We may collect non-personally identifiable information (i.e., information of an anonymous nature) such as device type, browser type, operating system, page views, usage, and similar data from your visits to our websites.
    </p>
   <p>
       When a visitor performs a search within our websites, FPA INDIA may record information identifying the visitor or linking the visitor to the search performed. We may also record limited information associated with every search request made by a visitor. That information is used to, among other things, solve technical problems with the service and to calculate overall usage statistics.
   </p>
   <h4>How Does FPA INDIA Use My Data?</h4>
   <p>
       FPA INDIA uses information voluntarily submitted by members and customers in the following ways:
   </p>
   <p>
       <div className="checklist style3 mb-45">
        <ul>
            <li>
                To provide you with programs, products, services, or information you have requested from FPA INDIA
            </li>
            <li>
                To develop new and improved services by analyzing demographic or usage trends and by measuring demographics and interests regarding specific areas of FPA INDIA (including the website)
            </li>
            <li>
                To communicate with you about your FPA INDIA account
            </li>
            <li>
                To process payments, including membership dues, registration and exam fees, subscriptions, voluntary contributions, and products and services. FPA INDIA is compliant with the data security standards of the payment card industry (PCI-DSS compliant)
            </li>
            <li>
                To administer our certification programs, examination scheduling, and registration processes with our partner exam providers
            </li>
            <li>
                To manage your participation in FPA INDIA governance activities, such as Global and local Boards, Standing Advisory Committees, and other volunteer leadership roles
            </li>
            <li>
                To provide you with prompt and effective customer service
            </li>
            <li>
                To support the operations of FPA INDIA such as account maintenance and recordkeeping, troubleshooting problems, resolving disputes, and for internal controls and audits
            </li>
            <li>
                To inform you about programs, products, and services offered by FPA INDIA and/or its contracted business partners
            </li>
            <li>
                To provide advertisements that are consistent with FPA INDIA’s organizational purpos
            </li>
            <li>
                To conduct surveys for FPA INDIA or on behalf of individual members performing scholarly research
            </li>
            <li>
                To carry out FPA INDIA’s Ethics Compliance Policy and/or conduct investigations as required by law
            </li>
            <li>
                To promote networking between members
            </li>
        </ul>
    </div>
    There are a number of different basis on which we will process your personal data, depending on the type of data and the purpose. Generally, we will process your personal data to provide you with the programs, products, services, and information that you have requested from us or where it is necessary to perform our contract with you (i.e., membership benefits). If none of these conditions apply, we may process your personal data in instances when it is in our legitFPA INDIAte interests and in alignment with the purposes defined in this Policy.
   </p>
   <p>
       Unless a longer retention period is required by applicable laws or regulations, we will retain your information for as long as your FPA INDIA account is active, as well as for an additional period afterwards to cover any outstanding issues or queries that may arise in relation to your account. This period of retention is subject to our review and alteration.
   </p>
   <h4>
       How Does FPA INDIA Share or Disclose My Data?
   </h4>
   <p>
       FPA INDIA will not sell your personally identifiable information to anyone. FPA INDIA may disclose your personal data to third parties. Our third-party data processors are required to securely maintain your personal data, following regulations and best practices, and will use the data only as needed to support the delivery of services to you on FPA INDIA’s behalf. Further, personal data will be disclosed only to contracted third-party service providers under the following categories:
   </p>
   <p>
       <div className="checklist style3 mb-45">
        <ul>
            <h5>Technology/Operations</h5>
            <li>
                Hosting facilities to securely store, transmit, and process your data
            </li>
            <li>
                Technology solution providers who assist in the development of our software platforms
            </li>
            <li>
                Credit card processors to securely process your payments to FPA INDIA
            </li>
            <li>
                Compliance authorities we utilize for legal issues, audits, and tax purposes
            </li>
            <li>
                Mailing houses for the fulfillment of our products and services
            </li>
            <li>
                Identity management providers for user access to confidential information
            </li>
            <li>
                Legal authorities to comply with an inquiry made regarding a subpoena, warrant, or other court order
            </li>
            <h5>Marketing/Communications</h5>
            <li>
                Advertising, marketing, and social media providers to conduct targeted advertisements related to professional development and educational opportunities
            </li>
            <li>
                Research providers who may conduct surveys and studies on behalf of FPA INDIA
            </li>
            <li>
                Reporting and analytic tools to allow data mining and monitoring
            </li>
            <li>
                Affiliate partners to provide special offers as a benefit to our members
            </li>
            <li>
                Marketing automation solutions to provide outbound email marketing services
            </li>
            <h5>Product and Services</h5>
            <li>
                Third-party call centers to conduct outreach initiatives on behalf of FPA INDIA regarding our programs, products, and services
            </li>
            <li>
                Learning platform providers who develop, host, and store information related to Continuing Professional Education (CPE) products
            </li>
            <li>
                Professional certification providers for the delivery of digital badges and scheduling of FPA INDIA examinations
            </li>
            <li>
                Online community platform providers to allow member/customer networking benefits
            </li>
            <li>
                Event solution providers for the hosting and delivery of webinars and conferences, including onsite support
            </li>
            <li>
                Verification purposes related to credentials from FPA INDIA/ICMA.
            </li>
        </ul>
    </div>
   </p>
   <h4>
       How Does FPA INDIA Use Cookies?
   </h4>
   <p>
       Cookies are stored on a user's computer and are designed to hold a modest amount of data specific to a particular client and website. We use cookies to make FPA INDIA’s websites easier to use and to better tailor our services to your interests and needs which may expedite your future activities and experiences on our websites. We also use cookies to compile anonymous, aggregated statistics to help us improve our websites’ structure and content. We cannot identify you personally from this information.  Some of the website pages you visit may also collect information using pixel tags (also called clear gifs) that may be shared with contracted third parties to support our promotional activities and website development. We use a number of contracted suppliers who may also set cookies on your device, on FPA INDIA’s behalf when you visit FPA INDIA websites, to allow them to deliver their services to you. Upon visiting FPA INDIA websites, you may receive cookies from third-party websites or domains. We endeavor to identify these cookies before they are used so that you can decide whether or not you want to accept them. You can change the settings to block cookies or to alert you when cookies are being sent to your device.
   </p>
   <p>
       By using FPA INDIA websites, you are consenting to our use of cookies in accordance with our Cookie Policy. If you do not agree to our use of cookies in this way, you may set your browser settings accordingly or not use FPA INDIA websites. If you disable the cookies that FPA INDIA uses, this may impact your user experience while on FPA INDIA websites.
   </p>
   <h4>
       Does FPA INDIA Collect Personal Data from Children?
   </h4>
   <p>
       FPA INDIA does not knowingly collect or post information from individuals under eighteen (18) years of age (“Children” or “Child”) to the extent that FPA INDIA is able to determine the age of users by their submissions to or communications with FPA INDIA.
   </p>
   <h4>
       International Data Privacy Protection
   </h4>
   <p>
       We may transfer your data (including personal information) to our regional offices and contracted affiliates outside the United States. They may use your personal information in connection with FPA INDIA activities to offer you products and services in your local region. All of our providers/partners will only process data on FPA INDIA’s behalf and will be held to the same provisions listed in this Privacy Policy. Your personal data will be handled using the appropriate safeguards following best practices.
   </p>
   <h4>FPA INDIA’s Security Measures</h4>
   <p>
       The security of a customer’s personal data is important to us. FPA INDIA takes technical, contractual, administrative, and physical security steps to protect the personal data that you provide and we have implemented numerous security features to prevent the unauthorized release of or access to personal data. FPA INDIA restricts access to your personal data by our employees by only allowing it on an as-needed basis for the purpose of providing benefits, programs, products, or services to you. In addition, FPA INDIA employees are trained about the importance of confidentiality and maintaining the privacy and security of your personal data. While every effort is made to maintain the privacy and security of your personal data, our security practices, processes, or technology do not guarantee absolute security of your information. It is in your best interest to take all normal personal precautions as well. Examples of precautions you can take are, but are not limited to, not sharing passwords, closing browsers when not using them, and not using public networks (e.g., internet cafes, etc.).
   </p>
   <h4>Third-Party Websites</h4>
   <p>
       FPA INDIA’s websites may contain links to other Internet websites. Unless we explicitly state otherwise, we have no control over these third-party sites and their privacy practices, and this Privacy Policy applies only to information you supply (or that we collect) in connection with use of FPA INDIA’s websites. You should review third-party privacy policies in connection with visiting FPA INDIA’s websites.
   </p>
   <h4>Communication Preferences</h4>
   <p>
       FPA INDIA provides you with communications on educational products and services, networking opportunities, certification, industry research, and other topics related to our mission.  You can manage your communication preferences by visiting your myFPA INDIA account and maintaining the settings on our website(s). Members may opt-out of additional forms of communication used by FPA INDIA for secondary purposes or used by FPA INDIA to send any correspondence to the member, by contacting FPA INDIA as indicated below.
   </p>
   <h4>
       FPA INDIA’s Right to Change Privacy Policy
   </h4>
   <p>
       FPA INDIA may change the content of its websites and Privacy Policy at any time. Please revisit our Privacy Policy from time to time to be sure you are familiar with our current policy.
   </p>
</div>

            </Col>
        </Row>
    </Container>
</div>
<Footer/>
    </>
  )
}

export default Privacypolicy