import React from 'react';
import Image from '../components/Image';
import { FaEye } from "react-icons/fa6";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { Link } from "react-router-dom";


const Gallery = () => {
  return (
    <>
    <Header/>
   
    <div className="gallery_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
    <div className="breadcumb-shape background-image"></div>
    <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
        <img src={img_1} alt="shape"/></div>
    <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
        <img src={img_2} alt="shape"/></div>
        <Container style={{height:'100%'}}>
            <Row style={{height:'100%'}}>
                <Col sm={12}>
                <div className="breadcumb-content text-center">
            <h1 className="breadcumb-title">Our Gallery</h1>
            <ul className="breadcumb-menu">
                <li><Link  to="/">Home</Link></li>
                <li><FaChevronRight /></li>
                <li>Gallery</li>
            </ul>
        </div>
                </Col>
            </Row>
        </Container>
</div>
<div className="space">
    <Container>
        <Row className='gy-4 masonary-active' style={{justifyContent: 'center'}}>
            <Col sm={4} className='filter-item gallery col-xxl-auto'>
            <div className="gallery-card">
                        <div className="gallery-img"><Image text="gallery_1_1" /> <a
                                 to="admin-fpa/upload/gallery/" className="gallery-btn popup-image"><FaEye /></a></div>
                        <div className="gallery-content">
                            <h2 className="gallery-title">Events</h2>
                        </div>
                    </div>
            </Col>
            <Col sm={4} className='filter-item gallery col-xxl-auto'>
            <div className="gallery-card">
                        <div className="gallery-img"><Image text="gallery_1_2" /> <a
                                 to="admin-fpa/upload/gallery/" className="gallery-btn popup-image"><FaEye /></a></div>
                        <div className="gallery-content">
                            <h2 className="gallery-title">Events</h2>
                        </div>
                    </div>
            </Col>
            <Col sm={4} className='filter-item gallery col-xxl-auto'>
            <div className="gallery-card">
                        <div className="gallery-img"><Image text="gallery_1_3" /> <a
                                 to="admin-fpa/upload/gallery/" className="gallery-btn popup-image"><FaEye /></a></div>
                        <div className="gallery-content">
                            <h2 className="gallery-title">Events</h2>
                        </div>
                    </div>
            </Col>
        </Row>
    </Container>
    </div>
    <Footer/>
    </>
  )
}

export default Gallery