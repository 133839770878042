import React from 'react'
import Image from '../components/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Affilitions = () => {
    var Affilitions = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <div className="space-bottom Affilitions_home_page_style">
        <Container>
        <div className="title-area text-center">
                <h2 className="sec-title fw-medium" style={{color: '#fff'}}>Affilitions</h2>
                <span className="sub-title" style={{color: '#c9c7c7'}}>Learning Partners</span>
            </div>
            <Row><Col sm={12}>
                <Slider {...Affilitions} className="row align-items-center as-carousel Affilitions-slider">
                <div className="moto-box moto-box-img">
                    <div className="moto-box_img"><Image text="affilitions-1" /></div>
                </div>

                {/* <div className="moto-box moto-box-img">
                    <div className="moto-box_img"><Image text="affilitions-2" /></div>
                </div> */}

                <div className="moto-box moto-box-img">
                    <div className="moto-box_img"><Image text="affilitions-3" /></div>
                </div>
                {/* <div className="moto-box moto-box-img">
                    <div className="moto-box_img"><Image text="affilitions-4" /></div>
                </div> */}
                <div className="moto-box moto-box-img">
                    <div className="moto-box_img"><Image text="affilitions-5" /></div>
                </div>
                <div className="moto-box moto-box-img">
                    <div className="moto-box_img"><Image text="affilitions-6" /></div>
                </div>
            </Slider>
            </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Affilitions