import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Faculty from './faculty/Faculty';
import About from './about/About';
import Testimonialspage from './testimonial/Testimonialspage';
import Event from './event/Event';
import Gallery from './gallery/Gallery';
import Contact from './contact/Contact';
import Recruiter from './recruiters/Recruiters';
import Course from './course/Course';
import Coursesdetails from './course/Coursesdetails';
import Blog from './blog/Blog';
import Blogdetails from './blog/Blogdetails';
import Eventdetails from './event/Eventdetails';
import Landingpage from './landing/Landingpage';
import Applyonline from './form/Applyonline';
import Cookie from './layout/privacy/Cookie';
import Termsandconditions from './layout/privacy/Termsandconditions';
import Privacypolicy from './layout/privacy/Privacypolicy';
import Landingpagetwo from './landing/Landingpagetwo';

// page
import Home from './layout/Home';

function App() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/"
          component={Home}
        />
        <Route
          exact
          path="/Faculty"
          component={Faculty}
        />
        <Route
          exact
          path="/About-Us"
          component={About}
        />
        <Route
          exact
          path="/Testimonials"
          component={Testimonialspage}
        />
        <Route
          exact
          path="/Events"
          component={Event}
        />
        <Route
          exact
          path="/Event/:slug/"
          component={Eventdetails}
        />
        <Route
          exact
          path="/Gallery"
          component={Gallery}
        />
        <Route
          exact
          path="/Contact-Us"
          component={Contact}
        />
        <Route
          exact
          path="/Recruiter"
          component={Recruiter}
        />
        <Route
          exact
          path="/Courses"
          component={Course}
        />
        <Route
          exact
          path="/Course/:slug/"
          component={Coursesdetails}
        />

        <Route
          exact
          path="/Blogs"
          component={Blog}
        />
        <Route
          exact
          path="/Blog/:slug/"
          component={Blogdetails}
        />
        <Route
          exact
          path="/Certified-Financial-Planner-Lucknow"
          component={Landingpage}
        />
        <Route
          exact
          path="/Apply-Online"
          component={Applyonline}
        />
        <Route
          exact
          path="/Cookie"
          component={Cookie}
        />
        <Route
          exact
          path="/Terms-And-Conditions"
          component={Termsandconditions}
        />
        <Route
          exact
          path="/Privacy-Policy"
          component={Privacypolicy}
        />
        <Route
          exact
          path="/Chartered-Wealth-Manager-Certification"
          component={Landingpagetwo}
        />
      </Switch>
    </>
  );
}

export default App;
