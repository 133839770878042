import React from 'react';
import Image from '../components/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";


const Recruiters = () => {
    return (
        <>
            <Header />
            <div className="Recruiters_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{ height: '460px' }}>
                <div className="breadcumb-shape background-image"></div>
                <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
                    <img src={img_1} alt="shape" /></div>
                <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
                    <img src={img_2} alt="shape" /></div>
                <Container style={{ height: '100%' }}>
                    <Row style={{ height: '100%' }}>
                        <Col sm={12}>
                            <div className="breadcumb-content text-center">
                                <h1 className="breadcumb-title">Recruiters</h1>
                                <ul className="breadcumb-menu">
                                    <li><NavLink  to="/">Home</NavLink></li>
                                    <li><FaChevronRight /></li>
                                    <li>Recruiters</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <section className="space-normal">
                <Container>
                    <Row className='Recruiters'>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-48" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-49" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-50" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-51" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-52" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-53" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-54" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-55" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-56" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-1" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-2" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-3" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-4" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-5" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-6" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-7" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-8" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-9" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-10" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-11" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-12" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-13" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-14" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-15" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-16" />
                            </div>
                        </Col> <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-17" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-18" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-19" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-20" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-21" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-22" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-23" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-24" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-25" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-26" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-27" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-28" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-29" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-30" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-31" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-32" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-33" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-34" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-35" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-36" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-37" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-38" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-39" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-40" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-41" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-42" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-43" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-44" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-45" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-46" />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="counter-card">
                                <Image text="p-47" />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default Recruiters