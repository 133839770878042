import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaUser } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationCity } from "react-icons/md";
import axios from "axios";

const Applyonline = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    message: "",
    course_name: "",
    amount: "",
    city: "",
    stream: "",
    razorpayPaymentId: "",
  });

  const [data, setData] = useState([]);

    const submitPayment = async () => {
      try {
        if (formData.razorpayPaymentId !== null) {
          const response = await axios.post(
            "https://fpaindia.in/fpa_india_admin/public/api/onlinepayment",
            formData
          );
          // console.log("Payment submitted successfully:", response.data);
          window.location.replace(response.data.url, "_self");
        } else {
          // console.log("razorpayPaymentId is null, API call not made");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get("https://fpaindia.in/fpa_india_admin/public/api/courses");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCourseData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const options = {
        key: "rzp_live_u56psVd2Cg1XoT",
        amount: formData.amount * 100, // amount in paisa
        currency: "INR",
        name: "FPAINDIA",
        description: "FPAINDIA Online Payment",
        // order_id: response.data.order_id, // Assuming the server returns the order ID
        handler: function (response) {
          formData.razorpayPaymentId = response.razorpay_payment_id;
          // alert(response.razorpay_payment_id);


          if (response.razorpay_payment_id !== null ) {
            submitPayment();
          }
        },
        prefill: {
          name: formData.first_name + " " + formData.last_name,
          email: formData.email,
        },
        notes: {
          address: formData.city,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();



   
    
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className="breadcumb-wrapper Applyonline_bg">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="form_wrapper" style={{ maxWidth: "767px" }}>
              <div className="form_container">
                <div className="title_container">
                  <h2>
                    You are just 1 Step away from the course of your choice.
                  </h2>
                  <p>
                    Fill in the form below. Make the payment online. Get started
                    Now !!
                  </p>
                </div>
                <div className="row clearfix">
                  <div className="">
                    <form
                      onSubmit={handleSubmit}
                      method="POST"
                      action="pay.php"
                      className="contact100-form validate-form"
                    >
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="first_name"
                              placeholder="First Name"
                              value={formData.first_name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="last_name"
                              placeholder="Last Name"
                              value={formData.last_name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <IoIosMail />
                            </span>
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaIndianRupeeSign />
                            </span>
                            <input
                              type="number"
                              name="amount"
                              placeholder="Amount"
                              value={formData.amount}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <MdLocationCity />
                            </span>
                            <input
                              type="text"
                              name="city"
                              placeholder="City"
                              value={formData.city}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaPhoneAlt />
                            </span>
                            <input
                              type="number"
                              name="mobile"
                              placeholder="Mobile Number"
                              value={formData.mobile}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field select_option">
                            <select
                              name="course_name"
                              value={formData.course_name}
                              onChange={handleChange}
                              required
                            >
                              <option>Select Course</option>
                              {data.map((course) => (
                                <option key={course.id} value={course.heading}>
                                  {course.heading}
                                </option>
                              ))}
                            </select>
                            <div className="select_arrow"></div>
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field select_option">
                            <select
                              name="stream"
                              value={formData.stream}
                              onChange={handleChange}
                              required
                            >
                              <option>Select Stream</option>
                              <option value="Short_Term_Programs">
                                Short Term Programs
                              </option>
                            </select>
                            <div className="select_arrow"></div>
                          </div>
                        </div>
                      </div>
                      <div className="input_field">
                        <textarea
                          name="message"
                          placeholder="Description"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="input_field">
                        <input
                          type="checkbox"
                          id="terms"
                          name="terms"
                        />
                        <label htmlFor="terms" style={{ textAlign: "left" }}>
                          I agree with terms and conditions
                        </label>
                      </div>
                      <button
                        className="as-btn Submit-button"
                        name="btn-submit"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                  <div className="online-form-bottom">
                    <p>Facing difficulties ??</p>
                    <p>Call us for assistance +917007662051</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Applyonline;
