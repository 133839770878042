import React from 'react';
import Header from './header/header';
import Footer from './footer/Footer';
import Hero from '../hero/Hero';
import Homecontent from '../homecontent/Homecontent';
import Recruiter from '../recruiter/Recruiter';
import Affilitions from '../affilitions/Affilitions';
import Testimonial from '../testimonial/Testimonial';
import Corporate from '../corporate/Corporate';
import Popup from '../popup/Popup';

const Home = () => {
  return (
    <>
    <Popup/>
    <Header/>
    <Hero/>
    <Homecontent />
    <Recruiter />
    <Affilitions />
    <Testimonial/>
    <Corporate />
    <Footer/>
    </>
  )
}

export default Home