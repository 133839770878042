import React from 'react';
import Image from '../components/Image';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";


const Testimonialspage = () => {
  return (
   <>
   <Header/>
   <div className="Testimonialspage_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
    <div className="breadcumb-shape background-image"></div>
    <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
        <img src={img_1} alt="shape"/></div>
    <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none"></div>
    <Container style={{height:'100%'}}>
        <Row style={{height:'100%'}}>
            <Col sm="12">
            <div className="breadcumb-content text-center">
            <h1 className="breadcumb-title">Testimonials</h1>
            <ul className="breadcumb-menu">
                <li><NavLink  to="/">Home</NavLink></li>
                <li><FaChevronRight /></li>
                <li>Testimonials</li>
            </ul>
        </div>
            </Col>
        </Row>
    </Container>
</div>
<section className="overflow-hidden bg-white space testimonials">
    <Container>
        <Row>
            <Col sm={6}>
            <div className="testi-box">
                        <div className="testi-box_content">
                            <div className="testi-box_img"><Image text="testi_1_1" />
                            </div>
                            <p className="testi-box_text">“ FPA INDIA had provided great knowledge in the field of Personal Finance, Stock Market,
                                Mutual Funds, and various more aspects. This is much beneficial for my Future, And I
                                would like to thank for this for making my future easy in the field of Financial
                                Business.”</p>
                        </div>
                        <div className="testi-box_bottom">
                            <div>
                                <h3 className="testi-box_name">Rohit Gupta</h3>
                            </div>
                            
                        </div>
                    </div>
            </Col>
            <Col sm={6}>
            <div className="testi-box">
                        <div className="testi-box_content">
                            <div className="testi-box_img"><Image text="testi_1_2" />
                            </div>
                            <p className="testi-box_text">“I always wanted to be a successful person. But the road to success very tuff and need a
                                right & solid guidance and support to reach there.
                                FPA INDIA has been super supportive and incredibly instrumental in shaping my career.
                                Thank you FPA INDIA.”</p>
                        </div>
                        <div className="testi-box_bottom">
                            <div>
                                <h3 className="testi-box_name">Prakash Singh Bisht</h3>
                            </div>
                            
                        </div>
                    </div>
            </Col>
            <Col sm={6}>
            <div className="testi-box">
                        <div className="testi-box_content">
                            <div className="testi-box_img"><Image text="testi_1_3" />
                            </div>
                            <p className="testi-box_text">“ The course has been good, I have enjoyed because of the way the modules are scheduled.
                                There is a lot of flexibility, so you can do other things. I really enjoyed it.
                                Particularly the writing and the theory aspects, when I joined FPA INDIA, every thing
                                was new to me but as the time goes on , I am now enjoying my studies.
                                Thanks a lot FPA INDIA for all support.”</p>
                        </div>
                        <div className="testi-box_bottom">
                            <div>
                                <h3 className="testi-box_name">Neha kumari</h3>
                            </div>
                        </div>
                    </div>
            </Col>
            <Col sm={6}>
            <div className="testi-box">
                        <div className="testi-box_content">
                            <div className="testi-box_img"><Image text="testi_1_4" />
                            </div>
                            <p className="testi-box_text">“It has been an amazing experience learning at FPA INDIA.
                                The faculty has been motivating throughout, and bringing out the best in me. They helped
                                me through the online teaching, doubt solving sessions, and up- to-do date notes. I
                                would like to thank FPA INDIA for their support and guidance.”</p>
                        </div>
                        <div className="testi-box_bottom">
                            <div>
                                <h3 className="testi-box_name">Soumya</h3>
                            </div>
                            
                        </div>
                    </div>
            </Col>
            <Col sm={6}>
            <div className="testi-box">
                        <div className="testi-box_content">
                            <div className="testi-box_img"><Image text="testi_1_5" />
                            </div>
                            <p className="testi-box_text">“ I'm so thankful that I chose FPA INDIA. I cherish the moments spent under the guidance
                                of eminent faculty members. I'd recommend FPA INDIA every time. Thank you for helping me
                                shape my career.”</p>
                        </div>
                        <div className="testi-box_bottom">
                            <div>
                                <h3 className="testi-box_name">Mansi Srivastava</h3>
                            </div>
                           
                        </div>
                    </div>
            </Col>
        </Row>
    </Container>
    </section>
    <Footer/>
   </>
  )
}

export default Testimonialspage