import React from 'react';
import Image from '../components/Image';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoMdCheckbox } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";


const Faculty = () => {
  return (
    <>
    <Header/>
    <div className="Faculty_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
    <div className="breadcumb-shape background-image"></div>
    <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
        <img src={img_1} alt="shape"/></div>
    <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
        <img src={img_2} alt="shape"/></div>
    <Container>
        <Row>
            <Col sm={12}>
            <div className="breadcumb-content text-center">
            <h1 className="breadcumb-title">Faculty</h1>
            <ul className="breadcumb-menu">
                <li><NavLink  to="/">Home</NavLink></li>
                <li><FaChevronRight /></li>
                <li>Faculty</li>
            </ul>
        </div>
            </Col>
        </Row>
    </Container>
</div>

<section className="bg-white space">
    <Container>
        <Row>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-1" />
                        <div className="team-content">
                            <h3 className="team-title"><a  to="/">Dr. Daya Dhar Raj Srivastava</a></h3>
                        </div>
                    </div>
                    <div className="team-content-box">
                        <p>Dr. Daya Dhar Raj Srivastava is a Senior Banking Professional having 40 years of experience
                            in
                            Banking industry and academics.
                            He is Visiting Profressor in various Universities.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> Certified Financial Planner</li>
                                <li> <IoMdCheckbox /> Chartered Wealth Manager</li>
                                <li> <IoMdCheckbox /> Ph.D.(Management),</li>
                                <li> <IoMdCheckbox />  Associate in Life Insurance</li>
                                <li> <IoMdCheckbox /> Licentiate in General Insurance</li>
                                <li> <IoMdCheckbox /> AMFI Certified and CAIIB.</li>
                                <li> <IoMdCheckbox /> A financial market expert .</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-2" />
                    <div className="team-content">
                            <h3 className="team-title"><a  to="/">Aditya Gupta</a></h3>
                        </div>
                    </div>
                    <div className="team-content-box">
                        <p>Having 1.5 year of work ex in Barclays as product controller analyst and 1.5 year of work ex
                            in Ca firm in audit, taxation and accounting domain</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> FRM (certified)</li>
                                <li> <IoMdCheckbox /> Chartered Accountant</li>
                                <li> <IoMdCheckbox /> CFA ( level 2 cleared)</li>
                                <li> <IoMdCheckbox /> Bcom (finance)</li>
                                <li> <IoMdCheckbox /> Financial modeling</li>
                                <li> <IoMdCheckbox /> Python, SQL, Advance excel</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-3" />
                        <div className="team-content">
                            <h3 className="team-title"><a  to="/">Mayank Aggarwal</a></h3>
                        </div>
                    </div>
                    <div className="team-content-box">
                        <p>Mayank Aggrawal is a finance professional with more than 6 years of experience in the field.
                            Have expertise in research and analysis of different financial instruments.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> Chartered Wealth Manager</li>
                                <li> <IoMdCheckbox /> PGPM</li>
                                <li> <IoMdCheckbox /> B.tech</li>
                                <li> <IoMdCheckbox /> Certified Credit Research Analyst</li>
                                <li> <IoMdCheckbox /> Equity Research Advance Module</li>
                                <li> <IoMdCheckbox /> Financial Modeling</li>
                                <li> <IoMdCheckbox /> AMFI Registered Mutual Fund Distributor</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-4" />
                    <div className="team-content">
                        <h3 className="team-title"><a  to="/">Vidhi Verma</a></h3>
                    </div>
                </div>
                    <div className="team-content-box">
                        <p>More than 3 years of experience in different segments of the Investments & training.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> Certified Financial Planner</li>
                                <li> <IoMdCheckbox /> MBA in Finance & HR</li>
                                <li> <IoMdCheckbox /> AMFI Registered Mutual Fund Distributor</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-10" />
                    <div className="team-content">
                        <h3 className="team-title"><a  to="/">Abhishek Pal</a></h3>
                    </div>
                </div>
                    <div className="team-content-box">
                        <p>Abhishek Pal is a Finance Professional with more than 10 years of training and Industry experience having expertise in Direct taxes and Indirect taxes, Financial and Cost Accounting, Bank Audits, Mutual Fund, Secuirity Analysis and Portfolio Management.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> CA (Intermediate)</li>
                                <li> <IoMdCheckbox /> CMA (Intermediate)</li>
                                <li> <IoMdCheckbox /> MBA (Finance)</li>
                                <li> <IoMdCheckbox /> PGDM (Finance)</li>
                                <li> <IoMdCheckbox /> Various Cerfication Course in the field of Finance</li>
                                <li> <IoMdCheckbox /> Certified GST Trainer</li>
                                <li> <IoMdCheckbox /> Advisor of Investment Portfolio; Mutual Fund</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-5" />
                    <div className="team-content">
                        <h3 className="team-title"><a  to="/">Dr. Mayank Singh </a></h3>
                    </div>
                </div>
                <div className="team-content-box">
                        <p>Associate Professor at Lucknow public college of professional studies, having 12 plus year of experience in teaching MBA, BBA, Bcom Hons, Students.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> PhD in (Management)</li>
                                <li> <IoMdCheckbox /> Qualified UGC NET (Management)</li>
                                <li> <IoMdCheckbox /> MBA in Finance & Control</li>
                                <li> <IoMdCheckbox /> AMFI Registered Mutual Fund Distributor</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-6" />
                    <div className="team-content">
                        <h3 className="team-title"><a  to="/">Suraj Gupta</a></h3>
                    </div>
                </div>
                <div className="team-content-box">
                        <p>8 Years of experience in Financial Product Distribution & Training.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> Certified Financial Planner</li>
                                <li> <IoMdCheckbox /> M.Com </li>
                                <li> <IoMdCheckbox /> AMFI Registered Mutual Fund Distributor</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={4} className='gy-4' >
            <div className="team-grid">
                    <div className="team-img"> <Image text="Faculty-7" />
                    <div className="team-content">
                        <h3 className="team-title"><a  to="/">Aviral Agarwal</a></h3>
                    </div>
                </div>
                <div className="team-content-box">
                        <p>Aviral Agarwal is a Super Specialized Finance Professional serving more than 500 Clients across India.
Having  7 plus years of experience in different domains of financial services, education & training.</p>
                        <div className="checklist mb-1">
                            <ul>
                                <li> <IoMdCheckbox /> Certified Financial Planner</li>
                                <li> <IoMdCheckbox /> BA</li>
                                <li> <IoMdCheckbox /> LLB</li>
                                <li> <IoMdCheckbox /> AMFI Registered Mutual Fund Distributor</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
</section>
<Footer/>
    </>
  )
}

export default Faculty