import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import axios from 'axios';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    message: '',
    course_name: '',
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get("https://fpaindia.in/fpa_india_admin/public/api/courses");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCourseData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        'https://fpaindia.in/fpa_india_admin/public/api/register',
        formData
      );

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsOpen(true);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      alert('Form submitted successfully!');
      setIsOpen(false); // Close the popup after submission
      // Optionally, you can reset the form fields here
      setFormData({
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        message: '',
        course_name: '',
      });
    }
  }, [isSubmitted]);

  return (
    <>
      <div>
        {isOpen && (
          <div className="popup">
            <div className="form_wrapper">
              <div className="form_container">
                <div className="title_container">
                  <h2>Request a Call Back</h2>
                </div>
                <div className="row clearfix">
                  <div className="">
                    <form
                      action=""
                      method="POST"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}>
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="first_name"
                              placeholder="First Name"
                              value={formData.first_name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="last_name"
                              placeholder="Last Name"
                              required
                              value={formData.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input_field">
                        <span>
                          <IoIosMail />
                        </span>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input_field">
                        <span>
                          <FaPhoneAlt />
                        </span>
                        <input
                          type="number"
                          name="mobile"
                          placeholder="Mobile Number"
                          required
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input_field select_option">
                        <select
                          name="course_name"
                          value={formData.course_name}
                          onChange={handleChange}
                          required
                        >
                          <option>Select Course</option>
                          {data.map((course) => (
                            <option key={course.id} value={course.heading}>
                              {course.heading}
                            </option>
                          ))}
                        </select>
                        <div className="select_arrow"></div>
                      </div>
                      <div className="input_field">
                        <textarea
                          type="text"
                          name="message"
                          placeholder="Description"
                          value={formData.massage}
                          onChange={handleChange} required></textarea>
                      </div>
                      <div className="input_field">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        <label
                          style={{ textAlign: 'left' }}>
                          I agree with terms and conditions
                        </label>
                      </div>
                      <button
                        className="as-btn Submit-button"
                        name="submit"
                        type="submit">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <button
                className="buttonStyle close"
                id="model_close_button"
                onClick={handleClose}>
                <IoMdClose />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Popup;
