import React from 'react';

const Serverimage = ({ text }) => {
  return (
    <img
      src={`https://fpaindia.in/fpa_india_admin/public/${text}`}
      alt={text}
      loading="lazy"
    />
  );
};

export default Serverimage;
