import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Getintouch = () => {
    const [isRedirect, setIsRedirect] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [formData, setFormData] = useState({
        // Define your form fields here
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        message: '',
        course_name: '',
    });

    const [data, setData] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Make an HTTP POST request using Axios
            const response = await axios.post(
                'https://fpaindia.in/fpa_india_admin/public/api/register',
                formData
            );

            // console.log(response.data);
            window.alert('Form submitted successfully!'); // Alert upon successful submission
            setIsRedirect(true); // Set state to trigger redirection
        } catch (error) {
            // Handle errors
            console.error('Error submitting form:', error);
            setErrorMessage('An error occurred. Please try again.'); // Set error message
        }
    };

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await axios.get("https://fpaindia.in/fpa_india_admin/public/api/courses");
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchCourseData();
    }, []);

    useEffect(() => {
        if (isRedirect) {
            // Redirect logic here
            window.location.reload(); // Reload the page
        }
    }, [isRedirect]);

    const handleClose = () => {
        setIsOpen(false);
    };

    if (redirect) {
        // Redirect to the same page
        window.location.reload(); // You can also use React Router for navigation
    }

    return (
        <>
            {errorMessage && <p>{errorMessage}</p>} {/* Display error message if exists */}
            <div className="contact-form-wrap background-image">
                <span className="sub-title">Contact With Us!</span>
                <h2 className="border-title">Get in Touch</h2>
                <p className="mt-n1 mb-30 sec-text">Have a inquiry or some feedback for us? Fill out the form below to contact our team. please contact us at support@fpaindia.in</p>
                <form action="" method="POST" className="contact-form ajax-contact" encType="multipart/form-data"
                    onSubmit={handleSubmit}>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <input type="text" name="first_name" placeholder="First Name" class="form-control style3" value={formData.first_name}
                                onChange={handleChange} required/>
                            <i class="fal fa-user"></i>
                        </div>
                        <div class="form-group col-md-6">
                            <input type="text" name="last_name" placeholder="Last Name" required class="form-control style3" value={formData.last_name}
                                onChange={handleChange} />
                            <i class="fal fa-user"></i>
                        </div>
                        <div class="form-group col-md-6">
                            <input type="email" name="email" placeholder="Email" required class="form-control style3" value={formData.email}
                                onChange={handleChange} />
                            <i class="fal fa-envelope"></i>
                        </div>
                        <div class="form-group col-md-6">
                            <input type="number" name="mobile" placeholder="Mobile Number" required class="form-control style3" value={formData.mobile}
                                onChange={handleChange} />
                            <i class="fal fa-phone"></i>
                        </div>
                        <div class="form-group col-md-12">
                            <select
                                name="course_name"
                                value={formData.course_name}
                                onChange={handleChange}
                                required
                            >
                                <option>Select Course</option>
                                {data.map((course) => (
                                    <option key={course.id} value={course.heading}>
                                        {course.heading}
                                    </option>
                                ))}
                            </select>
                            <i class="fal fa-chevron-down"></i>
                        </div>
                        <div class="form-group col-12">
                            <textarea type="text" name="message" placeholder="Description" class="form-control style3" value={formData.massage}
                                onChange={handleChange} required></textarea>
                            <i class="fal fa-comment"></i>
                        </div>
                        <div class="form-btn col-12 mt-10">
                            <button class="as-btn" name="submit" type="submit">Send Message <i class="fas fa-long-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                    <p class="form-messages mb-0 mt-3"></p>
                </form>
            </div>
        </>
    )
}

export default Getintouch;
