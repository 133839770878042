import React, { useRef } from "react";
import Image from "../components/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Testimonial = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleTabClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <section className="space Testimonial_bg_img">
      <Container>
        <Row className="padding-normal">
          <Col sm={6} className="text-center text-lg-start">
            <div className="Testimonial_center_left">
            <div className="mb-30 mb-xl-5">
              <span className="sub-title Happy-Students">
                <i className="fal fa-book me-2"></i>Happy Students
              </span>
              <h2 className="fs-40 text-white fw-light mb-0 mt-n3">
                Our Student’s Says
              </h2>
              <h3 className="h2 text-white">About FPA INDIA</h3>
            </div>
            <Link to="/Testimonials" className="as-btn style2">
              Give Feedback<i className="fas fa-arrow-right ms-2"></i>
            </Link>
            </div>
          </Col>
          <Col sm={6}>
            <div className="testi-card-tab" data-asnavfor=".testi-card-slide">
              {[1, 2, 3, 4, 5].map((index) => (
                <button
                  key={index}
                  className={`tab-btn ${index === 1 ? "active" : ""}`}
                  type="button"
                  onClick={() => handleTabClick(index - 1)}
                >
                  <Image text={`testi_1_${index}`} />
                </button>
              ))}
            </div>
            <div
              className="testi-card-slide as-carousel"
              data-fade="true"
              data-slide-show="1"
            >
              <Slider {...settings} ref={sliderRef}>
                <div>
                  <div className="testi-card">
                    <p className="testi-card_text">
                      FPA INDIA had provided great knowledge in the field of
                      Personal Finance, Stock Market, Mutual Funds, and various
                      more aspects. This is much beneficial for my Future, And I
                      would like to thank for this for making my future easy in
                      the field of Financial Business.
                    </p>
                    <h3 className="testi-card_name">Rohit Gupta</h3>
                  </div>
                </div>
                <div>
                  <div className="testi-card">
                    <p className="testi-card_text">
                      I always wanted to be a successful person. But the road to
                      success very tuff and need a right & solid guidance and
                      support to reach there. FPA INDIA has been super
                      supportive and incredibly instrumental in shaping my
                      career. Thank you FPA INDIA.
                    </p>
                    <h3 className="testi-card_name">Prakash Singh Bisht</h3>
                  </div>
                </div>
                <div>
                  <div className="testi-card">
                    <p className="testi-card_text">
                      The course has been good, I have enjoyed because of the
                      way the modules are scheduled. There is a lot of
                      flexibility, so you can do other things. I really enjoyed
                      it. Particularly the writing and the theory aspects, when
                      I joined FPA INDIA, every thing was new to me but as the
                      time goes on , I am now enjoying my studies. Thanks a lot
                      FPA INDIA for all support.
                    </p>
                    <h3 className="testi-card_name">Neha kumari</h3>
                  </div>
                </div>
                <div>
                  <div className="testi-card">
                    <p className="testi-card_text">
                      It has been an amazing experience learning at Financial
                      FPA INDIA. The faculty has been motivating throughout, and
                      bringing out the best in me. They helped me through the
                      online teaching, doubt solving sessions, and up- to-do
                      date notes. I would like to thank FPA INDIA for their
                      support and guidance.
                    </p>
                    <h3 className="testi-card_name">Soumya</h3>
                  </div>
                </div>
                <div>
                  <div className="testi-card">
                    <p className="testi-card_text">
                      I'm so thankful that I chose FPA INDIA. I cherish the
                      moments spent under the guidance of eminent faculty
                      members. I'd recommend FPA INDIA every time. Thank you for
                      helping me shape my career.
                    </p>
                    <h3 className="testi-card_name">Mansi Srivastava</h3>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
