import React from 'react';
import Image from '../components/Image';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";

// icon
import { IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { TbHandClick } from "react-icons/tb";


const About = () => {
    return (
        <>
            <Header />
            <div className="about_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
    <div className="breadcumb-shape background-image"></div>
    <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
        <img src={img_1} alt="shape"/></div>
    <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
        <img src={img_2} alt="shape"/></div>
                <Container style={{ height: '100%' }}>
                    <Row style={{ height: '100%' }}>
                        <Col sm={12}>
                        <div className="breadcumb-content text-center">
            <h1 className="breadcumb-title">About Us</h1>
            <ul className="breadcumb-menu">
                <li><NavLink  to="/">Home</NavLink></li>
                <li><FaChevronRight /></li>
                <li>About Us</li>
            </ul>
        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="space">
                <Container>
                    <Row>
                        <Col sm={5}>
                            <Image text="aboutpage" className="aboutpage_img" />
                        </Col>
                        <Col sm={7} className='about-us-page-top'>
                            <div className="title-area mb-35"><span className="sub-title">About FPA INDIA</span>
                            </div>
                            <p className="mt-n2 mb-35">FPA INDIA is a national-level academic institute that provides super specialized
                                education & training in Financial Planning & Wealth Management, Accounting and Auditing domains. FPA
                                INDIA was established by top-ranked Finance Professionals having years of experience in different
                                spheres of finance. FPA INDIA is an Authorised Education Partner of the American Academy of
                                Financial Management (AAFM). FPA INDIA proposes industry-ready job-oriented programs for fresher
                                students, as well as corporates and working specialists. We are serving as a leading training
                                provider of courses like Chartered Wealth Manager (CWM), Accredited Financial Analyst (AFA),
                                Chartered Real Estate Professional, Chartered Trust & Estate Planner(CTEP), Chartered Portfolio
                                Manager (CPM), Certified Financial Planner (CFP), Chartered Financial Analyst (CFA), Certified
                                Management Accountant (CMA), Financial Risk Manager (FRM), Certified Internal Auditor (CIA), & many
                                more certification programs running by NSE, & other financial institutions.
                            </p>
                        </Col>
                        <Col sm={12}>
                            <div className="main-content-abot-page">
                                <h4>Vision:</h4>
                                <p>
                                    Our vision is to operate for better programs as well as furnish better proficiency to the
                                    souls who want to make their careers in Financial Sectors. FPA INDIA oversees to develop of the
                                    mindset of souls who are unfamiliar with better programs finance sector and striving with their
                                    career path with lack of mastery, with productive skills and job-oriented courses.
                                </p>
                                <h4>Mission:</h4>
                                <p>
                                    “TO CREATE A FINANCIALLY AWARED AND EMPOWERED INDIA”
                                </p>
                                <p>
                                    Everyone deserves a financial pedagogy.
                                </p>
                                <p>
                                    To formulate awareness and educate the individuals about various Financial Careers opportunities
                                    and render necessary tells and proficiency, change attitudes to translate knowledge into
                                    behaviour and provide aid and assistance to the public. Strengthening appropriate standards of
                                    competence for financial education and literacy through examination and continuing
                                    education, and to make individuals understand their rights and responsibilities. Financially
                                    Aware individuals are better able to take control of their circumstances, improve their quality
                                    of life, and ensure a more stable future for themselves. FPA INDIA’s mission is to inspire
                                    education and financial decision-making for individuals at every stage of life.
                                </p>
                                <h4>AFFILIATIONS:</h4>
                                <p>
                                    <div className="checklist style3">
                                        <p><strong><TbHandClick />Authorised Education Partner(AAFM), American Academy of Financial Management.</strong></p>
                                    </div>
                                    The American Academy of Financial Management is the international professional organization for
                                    Wealth Managers, Private Bankers, Portfolio Managers, Investment Advisors, Asset Managers, Project
                                    Manager and Trust and Estate Practitioners Worldwide .AAFM has set up its Indian Chapter American
                                    Academy of Financial Management India Private Limited, (AAFM India). AAFM as a Standard Setting Body
                                    in India with the objective to promote Financial Advisory & Wealth Management in the country. AAFM
                                    India is responsible for ascertaining Curriculum Design, Certification of membership and licensing
                                    standards in India. AAFM India has exclusive rights to offer various Financial Courses,
                                    International Financial Certifications, Designations and other Educational Programs offered by AAFM
                                    including the prestigious and highest designation globally appreciated in the Field of Wealth
                                    Management and Private Banking Chartered Wealth Manager(CWM).AAFM body is also an exclusive partner
                                    to SBI Exclusive for E-Learning Platform for their employees.
                                </p>
                                <p>
                                    <div className="checklist style3">
                                    <p><strong><TbHandClick />ICoFP(International College of Financial Planning)</strong></p>                                            
                                    </div>
                                    ICoFP is one of the India’s leading educational institutions in financial services domain.
                                    International College of Financial Planning, established in the year 2002, with an objective to
                                    educate leaders who make a difference in the financial world.
                                </p>
                                <p>ICoFP is India’s one of oldest and largest investment services organization for more than last
                                    five decades and have served a million+ Indian Investors across the world. ICoFP offers industry
                                    integrated full time Masters programs which integrate the curriculum of Chartered Financial
                                    Analyst (CFA) Program and Certified Financial Planner (CFPCM), the most acclaimed professional
                                    certifications globally and wide range of short term finance programs.</p>
                                <p>
                                    <div className="checklist style3">
                                    <p><strong><TbHandClick />
                                                Edudelphi Education </strong></p>
                                    </div>
                                    The Premier Professional Training Institute of Middle East is now in India.
                                </p>
                                <p>
                                    The Edudelphi Education is a premium professional training provider established to provide
                                    unique, innovative and effective training opportunities for an individual’s who wants to enhance
                                    and upgrade their knowledge and skills, not only to meet manpower requirements, but also daily
                                    life circumstances. The Edudelphi Education impart the highest quality of education and quality
                                    exposure to help in developing the next generation of business leaders with global outlook and
                                    capability of adapting to the fast changing business environment of the world. By introducing
                                    new specialized courses with designation such as ACCA(UK),CMA(US),CPA(US),CFA(US),FRM(US), CIA,
                                    etc., and adopting an innovative interactive technique, the institute has been constantly
                                    working over individual’s to develop professionals with vision, courage, and dedication to
                                    initiate and reach to their target position.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className='about-us-page-bottom'>
                        <Col sm={4}>
                            <div className="contact-info">
                                <div className="contact-info_icon"><FaLocationDot /></div>
                                <div className="media-body">
                                    <h4 className="contact-info_title">Our Address</h4><span className="contact-info_text">4/338 Vivek Khand 4, Gomti Nagar, Lucknow</span>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="contact-info">
                                <div className="contact-info_icon"><FaPhone /></div>
                                <div className="media-body">
                                    <h4 className="contact-info_title">Phone Number</h4><span className="contact-info_text"><span>Mobile: <a  to="tel:8318061647">(+91) 8318 061 647</a></span> <span>Mobile: <a  to="tel:7007662051">(+91) 7007 662 051</a></span></span>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="contact-info">
                                <div className="contact-info_icon"><IoIosMail /></div>
                                <div className="media-body">
                                    <h4 className="contact-info_title">Email</h4><span className="contact-info_text"><span>Email: <a  to="mailto:info@fpaindia.in">info@fpaindia.in</a></span> <br></br><span>Email: <a  to="mailto:support@fpaindia.in">support@fpaindia.in</a></span></span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default About