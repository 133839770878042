import React, { useEffect, useState } from 'react';
import Image from '../components/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import Serverimage from '../components/Serverimage';
import HTMLReactParser from 'html-react-parser';

import { FaClock } from "react-icons/fa6";
import {
    FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, XIcon, LinkedinShareButton, LinkedinIcon, FacebookMessengerShareButton, FacebookMessengerIcon, PinterestShareButton, PinterestIcon, TelegramShareButton, TelegramIcon
  } from "react-share";

const Eventdetails = (props) => {
    const shareUrl = (window.location.href);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            'https://fpaindia.in/fpa_india_admin/public/api/findevent/' + props.match.params.slug
          );
          setData(response.data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []); 
  
    if (loading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }
  
    const date = new Date(data.data.created_at);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  return (
    <>
    <Header/>
   
    <div className="blog_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
   <div className="breadcumb-shape background-image"></div>
   <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
       <img src={img_1} alt="shape"/></div>
   <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
       <img src={img_2} alt="shape"/></div>
       <Container style={{height:'100%'}}>
           <Row style={{height:'100%'}}>
               <Col sm={12}>
               <div className="breadcumb-content text-center">
           <h1 className="breadcumb-title">{data.data.heading}</h1>
           <ul className="breadcumb-menu">
               <li><NavLink  to="/">Home</NavLink></li>
               <li><FaChevronRight /></li>
               <li>{data.data.heading}</li>
           </ul>
       </div>
               </Col>
           </Row>
       </Container>
       </div>
       <section className="space-top space-extra2-bottom">
        <Container>
            <Row style={{justifyContent:'center'}}>
                <Col sm={10}>
                <div className="course-single">
                    <div className="course-single-top">
                        <div className="course-img"><Serverimage text={data.data.user_image} />
                        <div className='social_button_share'>
                        <FacebookShareButton url={shareUrl}>
  <FacebookIcon size={50}/>
</FacebookShareButton>
<WhatsappShareButton url={shareUrl}>
  <WhatsappIcon size={50}/>
</WhatsappShareButton>
<TwitterShareButton url={shareUrl}>
  <XIcon size={50}/>
</TwitterShareButton>
<LinkedinShareButton url={shareUrl}>
  <LinkedinIcon size={50}/>
</LinkedinShareButton>
<FacebookMessengerShareButton url={shareUrl}>
  <FacebookMessengerIcon size={50}/>
</FacebookMessengerShareButton>
<PinterestShareButton url={shareUrl}>
  <PinterestIcon size={50}/>
</PinterestShareButton>
<TelegramShareButton url={shareUrl}>
  <TelegramIcon size={50}/>
</TelegramShareButton>

</div>
                            </div>
                        
                        <h2 className="course-title">{data.data.heading}</h2>
                        <div className="course-description">
                        {HTMLReactParser(data.data.description)}
                                </div>
                    </div>
                </div>
                </Col>
                {/* <Col sm={4}>
                <aside className="sidebar-area">
                    <div className="widget">
                        <h3 className="widget_title">Recent Events</h3>
                        <div className="recent-post-wrap">
                            <div className="recent-post">
                                <div className="media-img"><NavLink  to="/Blog-Details"><Image text="blog-s-1-1" /></NavLink></div>
                                <div className="media-body">
                                    <h4 className="post-title"><NavLink className="text-inherit"  to="/Blog-Details">How To Start
                                            Learn Online Study From Your Home</NavLink></h4>
                                    <div className="recent-post-meta"><NavLink  to="/blog.html"><FaClock />21/6/2023</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
                </Col> */}
            </Row>
        </Container>
</section>

<Footer/>
    </>
  )
}

export default Eventdetails