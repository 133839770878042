import React from 'react';
import Image from '../components/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Corporate = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <section className="space-normal Corporate-homepage">
        <Container>
            <div className="title-area text-center Recruiters">
            <h2 className="sec-title">Corporate Members</h2>
        </div>
        <Row>
            <Col sm={12}>
        <Slider {...settings}>
        <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-48"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-49"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-50"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-51"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-52"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-53"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-54"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-55"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-56"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-1"/>
                </div>
            </div>
            <div className="recruiter_img ">
                <div className="counter-card">
                    <Image text="p-2"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-3"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-4"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-5"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-6"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-7"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-8"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-9"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-10"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-11"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-12"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-13"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-14"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-15"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-16"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-17"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-18"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-19"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-20"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-21"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-22"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-23"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-24"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-25"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-26"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-27"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-28"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-29"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-30"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-31"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-32"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-33"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-34"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-35"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-36"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-37"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-38"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-39"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-40"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-41"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-42"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-43"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-44"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-45"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-46"/>
                </div>
            </div>
            <div className="recruiter_img">
                <div className="counter-card">
                    <Image text="p-47"/>
                </div>
            </div>
            </Slider>
            </Col>
        </Row>
        </Container>
</section>
  )
}

export default Corporate