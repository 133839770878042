import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import "./../App.css";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import "./landing.css";
import Logo from "../assets/img/logo/logo.png";

import img_1 from "../assets/img/log/CFPlogo-nomark.png";
import img_2 from "../assets/img/log/call.gif";
import img_3 from "../assets/img/log/cta_2_shape1.png";
import img_4 from "../assets/img/log/cta_2_shape2.png";
import img_5 from "../assets/img/log/cta_2_shape3.png";
import img_6 from "../assets/img/log/blog-3-bg-shape.png";
import img_7 from "../assets/img/log/instructor_1_shape1.png";
import img_8 from "../assets/img/log/become-instructor-thumb.png";
import img_9 from "../assets/img/log/team-shape_1_1.png";
import img_10 from "../assets/img/log/classes_l.jpg";
import img_11 from "../assets/img/log/event_img-2-1.png";
import img_12 from "../assets/img/log/event_img-2-3.png";
import Img_13 from "./../assets/img/landing/about_2_1.png";
import Img_14 from "./../assets/img/landing/about_1_2.png";
import Img_15 from "./../assets/img/landing/about_1_shape1.png";
import Img_16 from "./../assets/img/landing/cat-1_2.svg";
import Img_17 from "./../assets/img/landing/call.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Landingpage = () => {

  // console.log(props)
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if form is submitted
  const [isPopupOpened, setIsPopupOpened] = useState(false); // State to track if the popup has already been opened

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    message: "",
    course_name: "",
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get("https://fpaindia.in/fpa_india_admin/public/api/courses");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCourseData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://fpaindia.in/fpa_india_admin/public/api/register",
        formData
      );

      // Add this part to submit data to the new API endpoint
      await axios.post(
        "https://api.neodove.com/integration/custom/4f49a6ac-dc16-41a8-abad-5fd2c4a0abb7/leads",
        {
          "name": formData.first_name + ' ' + formData.last_name,
          "mobile": formData.mobile,
          "email": formData.email,
          "massage": formData.message,
          "course": formData.course_name,
        }
      );

      // console.log(response.data);
      setIsSubmitted(true); // Set form submission state to true upon successful submission
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isPopupOpened &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        setIsOpen(true);
        setIsPopupOpened(true); // Set the flag to indicate that the popup has been opened
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPopupOpened]);

  useEffect(() => {
    if (isSubmitted) {
      alert("Form submitted successfully!");
      // Redirect logic after form submission
      window.location.href = "/Certified-Financial-Planner-Lucknow"; // Replace '/redirect-url' with the URL you want to redirect to
    }
  }, [isSubmitted]);

  return (
    <>
      <div>
        {isOpen && (
          <div className="popup">
            <div className="form_wrapper">
              <div className="form_container">
                <div className="title_container">
                  <h2>Request a Call Back</h2>
                </div>
                <div className="row clearfix">
                  <div className="">
                    <form
                      action=""
                      method="POST"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}
                    >
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="first_name"
                              placeholder="First Name"
                              value={formData.first_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="last_name"
                              placeholder="Last Name"
                              required=""
                              value={formData.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input_field">
                        <span>
                          <IoIosMail />
                        </span>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required=""
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input_field">
                        <span>
                          <FaPhoneAlt />
                        </span>
                        <input
                          type="number"
                          name="mobile"
                          placeholder="Mobile Number"
                          required=""
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input_field select_option">
                        <select
                          name="course_name"
                          value={formData.course_name}
                          onChange={handleChange}
                          required
                        >
                          <option>Select Course</option>
                           <option value="CFP (Certified Financial Planner)">CFP (Certified Financial Planner)</option>
                        </select>
                        <div className="select_arrow"></div>
                      </div>
                      <div className="input_field">
                        <textarea
                          type="text"
                          name="message"
                          placeholder="Description"
                          value={formData.massage}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="input_field">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        <label style={{ textAlign: "left" }}>
                          I agree with terms and conditions
                        </label>
                      </div>
                      <button
                        className="as-btn Submit-button"
                        name="submit"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <button
                className="buttonStyle close"
                id="model_close_button"
                onClick={handleClose}
              >
                <IoMdClose />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="landing_page_style">
        <nav className="navbar navbar-expand-lg navbar-dark" id="myHeader">
          <Container className="container px-4">
            <a className="navbar-brand">
              <img className="logoicfp" src={Logo} />
            </a>

            <div className=" " id="navbarResponsive">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item callnowbtn">
                  <a className="nav-link" href="tel:7007662051">
                    <img src={img_2} /> <span> 7007662051</span>
                  </a>
                </li>
              </ul>
            </div>
          </Container>
        </nav>
        <div className="as-hero-wrapper border-normal">
          <Container>
            <Row>
              <Col sm={12}>
                <div
                  className="hero-slider-3 hero-slider-top as-carousel"
                  data-fade="true"
                  data-slide-show="1"
                  data-md-slide-show="1"
                >
                  <div className="as-hero-slide hero-1">
                    <div
                      className="as-hero-bg landing_bg_1"
                      data-overlay="overlay1"
                      data-opacity="1"
                    ></div>
                    <div className="container z-index-common">
                      <div className="row landing-page-top-hero-section">
                        <Col sm={8}>
                          <div className="hero-style1 hero-slider-2 line-full">
                            <img className="cfp_logo_top landing_page_cfp_logo_top" src={img_1} />
                            <h1 className="hero-title">
                              Elevate Your Career with Our Certified Financial
                              Planner Program!
                            </h1>
                            <br></br>
                            <p className="hero-text">
                              Join the ranks of financial leaders by enrolling
                              in our Certified Financial Planner program
                              designed for success.
                            </p>
                            <a
                              className="as-btn Submit-button button_width_auto openPopup"
                              onClick={openPopup}
                            >
                              Enroll Now and Secure Your Financial Career!
                            </a>
                          </div>
                        </Col>
                        <Col sm={4} className="landing-page-top-form-bottom-margin">
                        <div className="form_wrapper">
              <div className="form_container">
                <div className="title_container">
                  <h2>Request a Call Back</h2>
                </div>
                <div className="row clearfix">
                  <div className="">
                    <form
                      action=""
                      method="POST"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}
                    >
                      <div className="row clearfix">
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="first_name"
                              placeholder="First Name"
                              value={formData.first_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col_half">
                          <div className="input_field">
                            <span>
                              <FaUser />
                            </span>
                            <input
                              type="text"
                              name="last_name"
                              placeholder="Last Name"
                              required=""
                              value={formData.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input_field">
                        <span>
                          <IoIosMail />
                        </span>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required=""
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input_field">
                        <span>
                          <FaPhoneAlt />
                        </span>
                        <input
                          type="number"
                          name="mobile"
                          placeholder="Mobile Number"
                          required=""
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input_field select_option">
                        <select
                          name="course_name"
                          value={formData.course_name}
                          onChange={handleChange}
                          required
                        >
                          <option>Select Course</option>
                           <option value="CFP (Certified Financial Planner)">CFP (Certified Financial Planner)</option>
                        </select>
                        <div className="select_arrow"></div>
                      </div>
                      <div className="input_field">
                        <textarea
                          type="text"
                          name="message"
                          placeholder="Description"
                          value={formData.massage}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="input_field">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        <label style={{ textAlign: "left" }}>
                          I agree with terms and conditions
                        </label>
                      </div>
                      <button
                        className="as-btn Submit-button"
                        name="submit"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <button
                className="buttonStyle close"
                id="model_close_button"
                onClick={handleClose}
              >
                <IoMdClose />
              </button>
            </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <section>
          <div className="space overflow-hidden" id="about-sec">
            <div className="container">
              <div
                className="row align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div className="col-xl-6 width_45_box">
                  <div className="img-box1 mb-40 mb-xl-0 shape-mockup-wrap">
                    <div className="img1">
                      <img className="tilt-active" src={Img_13} alt="About" />
                    </div>
                    <div className="about-grid background-image">
                      <h3 className="about-grid_year">
                        <span className="counter-number">10</span>k
                        <span className="text-theme">+</span>
                      </h3>
                      <p className="about-grid_text">
                        Students Active Our Courses
                      </p>
                    </div>
                    <div className="img2">
                      <img className="tilt-active" src={Img_14} alt="About" />
                    </div>
                    <div
                      className="shape-mockup about-shape1 jump"
                      style={{ bottom: "0px", left: "0px" }}
                    >
                      <img src={Img_15} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="title-area mb-30">
                    <span className="sub-title">
                      <i className="fal fa-book me-2"></i> About
                    </span>
                    <h2 className="sec-title">
                      About Certified Financial Planning Program
                    </h2>
                  </div>
                  <p className="mt-n2 mb-25">
                    With over 45k+ successful career transitions in the past,
                    CFP Learning has designed an illustrious 200+ hourThe
                    program shall also prepare learners for CFP L1 jobs that are
                    accessible in this industry market. Certified Financial
                    Planner (CFP) is a leading Certification globally recognized
                    in the field of Financial Planning with over 213000 CFP
                    professionals.It is a globally recognized certification for
                    financial professionals. CFPs are trained and authorized to
                    provide comprehensive financial planning advice to
                    individuals, covering areas such as investments, retirement
                    planning, tax strategies, and estate planning. It is a
                    professional mark for financial planners granted by the
                    Financial Planning Standards Board (FPSB).
                  </p>
                  <div className="btn-group mt-40 openPopup">
                    <a
                      
                      className="as-btn"
                      onClick={openPopup}
                    >
                      Become a Certified Financial Planner Now!
                      <i className="fa-regular fa-arrow-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="counter">
          <div className="container">
            <div className="counter_top_content">
              <div className="row">
                <div className="col-sm-6 col-xl-4">
                  <div className="counter_top_content_h2">
                    <p>
                      <strong>100%</strong>Passing Rate
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-4">
                  <div className="counter_top_content_h2">
                    <p>
                      <strong>100%</strong> Placements
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-4">
                  <div className="counter_top_content_h2">
                    <p>
                      <strong>1500+</strong>Batches Completed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-width_1366">
              <div className="row justify-content-between box_counter_box">
                <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                  <div className="counter-card landing-page-counter-card">
                    <h2 className="counter-card_number">
                      <span className="counter-number">50,000</span>
                      <span className="fw-normal">+</span>
                    </h2>
                    <p className="counter-card_text">Placements</p>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                  <div className="counter-card landing-page-counter-card">
                    <h2 className="counter-card_number">
                      <span className="counter-number">4 - 8</span>
                      <span className="fw-normal">Lakh</span>
                    </h2>
                    <p className="counter-card_text">Avg Salary</p>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                  <div className="counter-card landing-page-counter-card">
                    <h2 className="counter-card_number">
                      <span className="counter-number">60</span>
                      <span className="fw-normal">%</span>
                    </h2>
                    <p className="counter-card_text">Salary Hike</p>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                  <div className="counter-card landing-page-counter-card">
                    <h2 className="counter-card_number">
                      <span className="counter-number">600</span>
                      <span className="fw-normal">+</span>
                    </h2>
                    <p className="counter-card_text">Hiring Companies</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="space-normal">
          <Container>
            <div className="title-area text-center cfp_landing_page_planner">
              <h2 className="sec-title">CERTIFIED FINANCIAL PLANNER (CFP)</h2>
              <h4>PROGRAM FRAMEWORK</h4>
              <p>
                A unique 4 Level Program with Specialist Certification at each
                level, making it 4-in-1 Program CFP Syllabus is designed in such
                a way that it covers all areas of personal finance & wealth
                management.
              </p>
            </div>
            <Row className="align-items-center mt-60">
              <Col sm={3}>
                <div className="moto-box moto-box-img-landing">
                  <div className="moto-box_img checklist">
                    <p>CFP LEVEL I</p>
                    <h4>FPSB® INVESTMENT SPECIALIST</h4>
                    <ul>
                      <li>Module 1 : Personal Financial Management</li>
                      <li>Module 2 : Investment Planning & Asset Management</li>
                      <li>
                        Module 3 : Regulatory Environment & Legal Compliances
                      </li>
                    </ul>
                  </div>
                  <a
                    className="as-btn Submit-button button_width_auto openPopup"
                    
                    onClick={openPopup}
                    id="openPopup"
                  >
                    Enroll Now!
                  </a>
                </div>
              </Col>
              <Col sm={3}>
                <div className="moto-box moto-box-img-landing">
                  <div className="moto-box_img checklist">
                    <p>CFP LEVEL II</p>
                    <h4>FPSB® RETIREMENT & TAX SPECIALIST</h4>
                    <ul>
                      <li>Module 1 : Retirement Planning</li>
                      <li>Module 2 : Tax Planning</li>
                    </ul>
                  </div>
                  <a
                    className="as-btn Submit-button button_width_auto openPopup"
                    
                    onClick={openPopup}
                    id="openPopup"
                  >
                    Enroll Now!
                  </a>
                </div>
              </Col>
              <Col sm={3}>
                <div className="moto-box moto-box-img-landing">
                  <div className="moto-box_img checklist">
                    <p>CFP LEVEL III</p>
                    <h4>FPSB® RISK & LEGACY SPECIALIST</h4>
                    <ul>
                      <li>Module 1 : Risk Management & Insurance</li>
                      <li>Module 2 : Estate Planning</li>
                    </ul>
                  </div>
                  <a
                    className="as-btn Submit-button button_width_auto openPopup"
                    
                    onClick={openPopup}
                    id="openPopup"
                  >
                    Enroll Now!
                  </a>
                </div>
              </Col>
              <Col sm={3}>
                <div className="moto-box moto-box-img-landing">
                  <div className="moto-box_img checklist">
                    <p>CFP FINAL</p>
                    <h4>FPSB® CERTIFIED FINANCIAL PLANNER</h4>
                    <ul>
                      <li>Module 1 : Financial Planning Process & Skills</li>
                      <li>Module 2 : Engaging Clients in Financial Planning</li>
                      <li>Module 3 : Integrated Financial Planning</li>
                      <li>Case Study & Capstone Project</li>
                    </ul>
                  </div>
                  <a
                    className="as-btn Submit-button button_width_auto openPopup"
                    
                    onClick={openPopup}
                    id="openPopup"
                  >
                    Enroll Now!
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="position-relative overflow-hidden space shape-mockup-wrap">
          <div className="cta-bg-img background-image landing_bg_2"></div>
          <div className="cta-bg-img2 background-image landing_bg_3"></div>
          <div
            className="shape-mockup cta-shape1 jump d-md-block d-none"
            style={{ bottom: "-7%", left: "-2%" }}
          >
            <img src={img_3} />
          </div>
          <div
            className="shape-mockup cta-shape2 jump-reverse d-md-block d-none"
            style={{ top: "-3%", right: "-1%" }}
          >
            <img src={img_4} />
          </div>
          <div
            className="shape-mockup cta-shape3 spin d-md-block d-none"
            style={{ top: "30%", right: "20%" }}
          >
            <img src={img_5} alt="img" />
          </div>
          <div className="container text-center">
            <div className="cta-wrap2">
              <div className="title-area text-center mb-35">
                <span className="sub-title white">
                  <i className="fal fa-book me-2"></i>FPA INDIA EDUPRO
                </span>
                <h2 className="sec-title text-white">
                  WHY<span className="text-theme2"> CFP</span> CERTIFICATION
                </h2>
                <p
                  className="cta-text white"
                  style={{ maxWidth: "767px", margin: "auto", width: "100%" }}
                >
                  Earning your CFP certification helps you enjoy a rewarding
                  career. Whether you are a student or a working professional or
                  and independent financial advisor, CFP Certification is the
                  mark of knowledge and credibility in BFSI industry and leads
                  you to multplying your growth in Career or Business.
                </p>
              </div>
              <div className="checklist mb-30">
                <ul>
                  <li>100% Placement Assurance</li>
                  <li>Learn Job-relevant Skills</li>
                  <li>Personal Branding</li>
                  <li>Learn From Industry Experts</li>
                  <li>In-class Simulations</li>
                  <li>1 on 1 mentorship study plan</li>
                </ul>
              </div>
            </div>
            <a
              className="as-btn Submit-button button_width_auto openPopup"
              
              id="openPopup"
              onClick={openPopup}
            >
              Start Your CFP Journey Today!
            </a>
          </div>
        </section>
        <section className="pt-60 pb-60 bg_img_landing_1">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-6 col-lg-3">
                <div className="category-card category_box_6">
                  <div className="category-card_icon">
                    <img src={Img_16} alt="image" />
                  </div>
                  <div className="category-card_content">
                    <h3 className="category-card_title">
                      <a href="course.html" >
                        100% Passing Rate
                      </a>
                    </h3>
                    <a
                      
                      className="as-btn openPopup"
                      onClick={openPopup}
                      
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="category-card category_box_6">
                  <div className="category-card_icon">
                    <img src={Img_16} alt="image" />
                  </div>
                  <div className="category-card_content">
                    <h3 className="category-card_title">
                      <a href="course.html" >
                        Industry expert Trainers with year of Experience as Cfp
                      </a>
                    </h3>
                    <a
                      
                      className="as-btn openPopup"
                      onClick={openPopup}
                      
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="category-card category_box_6">
                  <div className="category-card_icon">
                    <img src={Img_16} alt="image" />
                  </div>
                  <div className="category-card_content">
                    <h3 className="category-card_title">
                      <a href="course.html" >
                        International Course Structure Designed BY FPSB USA
                      </a>
                    </h3>
                    <a
                      
                      className="as-btn openPopup"
                      onClick={openPopup}
                      
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="category-card category_box_6">
                  <div className="category-card_icon">
                    <img src={Img_16} alt="image" />
                  </div>
                  <div className="category-card_content">
                    <h3 className="category-card_title">
                      <a href="course.html" >
                        World className LMS For Mock Test
                      </a>
                    </h3>
                    <a
                      
                      className="as-btn openPopup"
                      onClick={openPopup}
                      
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="category-card category_box_6">
                  <div className="category-card_icon">
                    <img src={Img_16} alt="image" />
                  </div>
                  <div className="category-card_content">
                    <h3 className="category-card_title">
                      <a href="course.html" >
                        Comprehensive className Room Training
                      </a>
                    </h3>
                    <a
                      
                      className="as-btn openPopup"
                      onClick={openPopup}
                      
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="category-card category_box_6">
                  <div className="category-card_icon">
                    <img src={Img_16} alt="image" />
                  </div>
                  <div className="category-card_content">
                    <h3 className="category-card_title">
                      <a href="course.html" >
                        100 % Placement Support [ Placement Starts From Module
                        1]
                      </a>
                    </h3>
                    <a
                      
                      className="as-btn openPopup"
                      onClick={openPopup}
                      
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="space-bottom landing_bg_4">
          <div className="container">
            <div
              className="title-area text-center pt-60 pb-30"
              style={{ maxWidth: "967px", width: "100%", margin: "auto" }}
            >
              <span className="sub-title">
                <i className="fal fa-book me-2"></i>FPA INDIA EDUPRO
              </span>
              <h2 className="sec-title">
                BECOME A CREDIBLE PERSONAL FINANCE EXPERT
              </h2>
              <p>
                When you manage the wealth for your clients, the first thing
                thing that matters for your clients is how credible you are. And
                this credibility comes from the knowledge that you carry, skills
                that you, business ethics that you follow, professional
                qualifications you have done. A right mix of all of these is
                what Certified Financial Planner (CFP) Program offers you. The
                program content is designed in such a way that you study both
                the global and indian content. CFP Study involves case studies
                and project which are to build practical skills of wealth
                management and financial planniing. As a part of CFP
                Certification,you also go thru the professional ethics standard
                and finally 4 global certifications from Financial Planning
                Standards Board (FPSB USA) make you a finance profesional whom
                clients trust and can bank upon.
              </p>
            </div>
            <div className="row box-center">
              <div className="col-xl-6 mb-30 mb-xl-0">
                <div className="cta-card background-image landing_bg_5">
                  <div className="title-area mb-40 width_Courses">
                    <h4 className="sec-title">STUDYING CFP COURSE</h4>
                    <p className="color_black">
                      For the complete CFP Program - CFP Level 1 to Level 4, It
                      takes around 250 hours of study spread across 6-10 months
                      depending upon the pace and type of study you opt for.
                    </p>
                    <a
                      className="as-btn Submit-button"
                      onClick={openPopup}
                      to="#from_top_move"
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="cta-card background-image landing_bg_6">
                  <div className="title-area mb-40 width_Courses">
                    <h4 className="sec-title">TAKING CFP EXAM</h4>
                    <p className="color_black">
                      CFP Exams are conducted online once in a month. CFP Level
                      1 to 3 are 2 hr exams where as CFP Final Level is a 3 hr
                      exam. All CFP Exams are MCQs conducted in a live proctured
                      way.
                    </p>
                    <a
                      className="as-btn Submit-button"
                      onClick={openPopup}
                      to="#from_top_move"
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mt-30 mb-xl-0">
                <div className="cta-card background-image landing_bg_7">
                  <div className="title-area mb-40 width_Courses">
                    <p className="color_black">
                      Become a Global Finance Professional with the super
                      specialized expertise in Financial Planning & Wealth
                      Management course.
                    </p>
                    <a
                      className="as-btn Submit-button"
                      onClick={openPopup}
                      to="#from_top_move"
                    >
                      Enroll now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="overflow-hidden position-relative shape-mockup-wrap pt-100"
          id="team-sec"
        >
          <div
            className="shape-mockup instructor-bg-shape1 jump d-lg-block d-none"
            style={{ bottom: "30%", left: "3%" }}
          >
            <img src={img_6} alt="img" />
          </div>
          <div
            className="instructor_bg_shape2 shape-mockup d-xl-block d-none"
            style={{ top: "3%", left: "-15%" }}
          >
            <img src={img_7} alt="img" />
          </div>
          <div className="container">
            <div className="row justify-content">
              <div className="col-xl-6 order-xl-2">
                <div className="become-instructor-img mb-5 mb-xl-0 ms-xl-5">
                  <img src={img_8} alt="img" />
                </div>
              </div>
              <div className="col-xl-6 order-xl-1">
                <div className="title-area mb-30">
                  <span className="sub-title">
                    <i className="fal fa-book me-1"></i> WHY STUDY CFP AT FPA
                    INDIA EDUPRO
                  </span>
                  <h2 className="sec-title">INDIA'S BEST CFP STUDY PROGRAM</h2>
                  <p>While you are at FPA INDIA EDUPRO</p>
                </div>
                <div className="row gy-4 justify-content">
                  <div className="col-sm-6">
                    <div className="become-instructor-wrap">
                      <p>FIRST</p>
                      <h4 className="box-title">
                        FPA INDIA EDUPRO is Lucknow's 1st and Largest CFP
                        Education Provider in classNameroom Training Mode.
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="become-instructor-wrap">
                      <p>BEST</p>
                      <h4 className="box-title">
                        Get India's most comprehensive CFP Study materials only
                        at FPA INDIA EDUPRO
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="become-instructor-wrap">
                      <p>HIGHEST</p>
                      <h4 className="box-title">
                        Highest CFP Exam Passing Rate since the start of CFP
                        Program in India
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-60">
              <div className="col-xl-6">
                <div className="checklist mb-40 checklist-black">
                  <ul>
                    <li>
                      Concept Books exclusively written by topmost financial
                      experts and CFP coaches
                    </li>
                    <li>
                      Work Books to allow you to do ample amount of practice the
                      questions for exam
                    </li>
                    <li>
                      Recorded Videos of CFP CLASSES so that you revise a lesson
                      as many times you can
                    </li>
                    <li>
                      Assignments during the CFP CLASSES to make you practice
                      the skills you are learning
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="checklist mb-40 checklist-black">
                  <ul>
                    <li>
                      Group Projects in your CFP batches to facilitate peer
                      learning & activities
                    </li>
                    <li>
                      Case Studies which bring reallife leanring of portfolio
                      management & planning
                    </li>
                    <li>
                      Online Mock Test to practice yourself for a CFP Exam line
                      online test enviornment
                    </li>
                    <li>
                      Doubt Clearing Sessions by best CFP faculties to brush up
                      your CFP Exams preparations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-12 text-center mb-30">
                <a
                  className="as-btn Submit-button openPopup"
                  
                >
                  Shape Your Financial Destiny – Enroll Now!
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="space background-image shape-mockup-wrap landing_bg_8">
          <div
            className="shape-mockup event-shape1 jump"
            style={{ top: "3%", left: "-3%" }}
          >
            <img src={img_9} alt="img" />
          </div>
          <div className="container">
            <div className="title-area text-center pt-60 pb-30">
              <span className="sub-title">
                <i className="fal fa-book me-2"></i> CFP CLASSES AT FPA INDIA
                EDUPRO
              </span>
              <h2 className="sec-title">
                Choose a CFP Coaching Medium , which suits you the most.
              </h2>
            </div>
            <div className="event-grid">
              <div className="event-img">
                <img src={img_10} alt="course" />
              </div>
              <div className="event-content higelite">
                <div className="event-bg-shape bg-mask landing_bg_9"></div>
                <div className="media-left">
                  <h3 className="event-title">
                    <a to="event-details.html">CFP classNameROOM LEARNING</a>
                  </h3>
                  <div className="event-meta">
                    <p className="fontsize-16">
                      CFP CLASSES at Lucknow are exclusively available in
                      classNameroom learning mode at<br></br> FPA INDIA EDUPRO
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="event-grid">
              <div className="event-img">
                <img src={img_11} alt="course" />
              </div>
              <div className="event-content">
                <div className="event-bg-shape bg-mask landing_bg_9"></div>
                <div className="media-left">
                  <h3 className="event-title">
                    <a to="event-details.html">CFP ONLINE CLASSES</a>
                  </h3>
                  <div className="event-meta">
                    <p>
                      Instructor Led Real Time CFP Online CLASSES are delivered
                      by the most experienced CFP faculties in India. Attend CFP
                      online CLASSES from wherever you are and learn with the
                      best.
                    </p>
                    <p>
                      Rated as the Most Popular CFP Study Mode in India & Across
                      the World.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="event-grid event-grid_last_box">
              <div className="event-img">
                <img src={img_12} alt="course" />
              </div>
              <div className="event-content">
                <div className="event-bg-shape bg-mask landing_bg_9"></div>
                <div className="media-left">
                  <h3 className="event-title">
                    <a to="event-details.html">CFP E-LEARNING MODE</a>
                  </h3>
                  <div className="event-meta">
                    <p>
                      CFP Pre-recorded Learning Videos , Books, Workbooks and
                      CFP Course Study Materials are available for those who
                      want to prepare for CFP exam on their own self.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cta-area-3 space-top pt-60 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-30 mb-lg-0">
                <div className="cta-card background-image landing_bg_10">
                  <div className="title-area mb-40 width_Courses">
                    <span className="sub-title text-white">
                      <i className="fal fa-book me-2"></i>Popular Courses CFP
                    </span>
                    <h4 className="sec-title text-white">
                      CFP WEEKDAYS FAST TRACK COURSE
                    </h4>
                    <p>
                      With online CFP CLASSES happening Monday to Friday - 5
                      Days a week and convenient late evening hours from 7 to
                      8:30 pm, you wrap up all 4 levels of CFP study in
                      approximately 6 months <br></br>time itself.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cta-card background-image landing_bg_11">
                  <div className="title-area mb-40 width_Courses">
                    <span className="sub-title text-white">
                      <i className="fal fa-book me-2"></i>Popular Courses CFP
                    </span>
                    <h4 className="sec-title text-white">
                      CONVENIENT WEEKEND CLASSES
                    </h4>
                    <p>
                      Conveniently study for CFP along with your work or studies
                      by taking weekend online & offline CFP CLASSES on Saturday
                      and Sunday morning & evening. Finish up all 4 levels of
                      CFP study in approximately 6-8 months
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="py-5 footer_landing_page">
          <h5 className="text-center">
            FPA INDIA EDUPRO EDUPRO PVT LTD
            <br></br>4/338 Vivek Khand 4, Gomti Nagar, Lucknow
            <br></br>(Authorized Learning Centre of International College of
            Financial Planning)
            <br></br>
          </h5>
        </footer>
        <div id="whatsapp_1">
    <div className="whatsapp_2">
        <div className="whatsapp_3">
            <a href="https://wa.me/7007662051" target="_blank" aria-label="Go to whatsapp" direction="column" order="whatsapp" className="whatsapp_4">
            <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      width: "100%",
      height: "100%",
      fill: "rgb(255, 255, 255)",
      stroke: "none",
    }}

  >
    <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
  </svg>
            </a>
        </div>
    </div>
    <a size="50" type="link"
        href="https://getbutton.io/?utm_campaign=multy_widget&amp;utm_medium=widget&amp;utm_source=www.nsim.in"
        target="_blank" aria-label="Go to GetButton.io website" className="sc-sbsi7l-0 cLcbjv whatsapp_6">
    </a>
</div>
<div id="whatsapp_1" style={{bottom:'80px'}}>
    <div className="whatsapp_2">
        <div className="whatsapp_3">
            <a direction="column" order="whatsapp" className="whatsapp_4" >
              <img src={Img_17}/>
            </a>
        </div>
    </div>
    <a href="https://getbutton.io/?utm_campaign=multy_widget&amp;utm_medium=widget&amp;utm_source=www.nsim.in" target="_blank" aria-label="Go to GetButton.io website" className="sc-sbsi7l-0 cLcbjv">
    </a>
</div>
      </div>
    </>
  );
};

export default Landingpage;
