import React from 'react';

const Image = ({ text }) => {
  return (
    <img
      src={`assets/img/${text}.jpg`}
      alt={text}
      loading="lazy"
    />
  );
};

export default Image;
