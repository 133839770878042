import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/logo/logo.png";
import { Link, NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// icon
import { IoMdClose } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";
import { FaBook } from "react-icons/fa6";
import axios from "axios";

const Header = () => {
  const [isClassAdded, setClassAdded] = useState(false);

  const handleClick = () => {
    setClassAdded(!isClassAdded);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get("https://fpaindia.in/fpa_india_admin/public/api/courses");
        setData(response.data); // Assuming the API response is in JSON format
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the function
    fetchData();
  }, []); // Empty

  return (
    <>
      <div
        className={
          isClassAdded ? "as-menu-wrapper as-body-visible" : "as-menu-wrapper"
        }
      >
        <div className="as-menu-area text-center">
          <button onClick={handleClick} className="as-menu-toggle">
            <IoMdClose />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={Logo} alt="FPAINDIA" loading="lazy" />
            </Link>
          </div>
          <div className="as-mobile-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/About-Us">About Us</Link>
              </li>
              <li>
                <Link to="/Recruiter">Recruiters </Link>
              </li>
              <li className="menu-item-has-children mobile_view_menu">
                <Link to="/Courses">
                  Courses <FaAngleDown />
                </Link>
                <ul className="sub-menu">
                  {data.map((item) => (
                    <li>
                      <Link to={"/Course/" + item.slug}>
                        <FaBook />
                        {item.heading}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <Link to="/Faculty">Faculty</Link>
              </li>
              <li>
                <Link to="/Events">Events</Link>
              </li>
              <li>
                <Link to="/Blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/Gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/Testimonials">Testimonials</Link>
              </li>
              <li>
                <Link to="/Contact-Us">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="as-header header-layout1">
        <div className="header-top">
          <Container>
            <Row className="justify-content-center justify-content-md-between align-items-center gy-2">
              <div className="col-auto d-none d-md-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <FaPhone />
                      <a href="tel:+91 8318061647">
                        +91 8318061647, 7007662051
                      </a>
                    </li>
                    <li className="d-none d-xl-inline-block">
                      <FaLocationDot />
                      4/338 Vivek Khand 4, Gomti Nagar, Lucknow
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links">
                  <ul>
                    <li className="d-none d-lg-inline-block">
                      <FaUser />
                      <Link to="/" className="login_register">
                        Login / Register
                      </Link>
                    </li>
                    <li className="d-lg-inline-block">
                      <FaUser />
                      <Link to="/Apply-Online">Apply Online</Link>
                    </li>
                    <li>
                      <div className="header-social">
                        <span className="social-title">Follow Us:</span>{" "}
                        <a
                          href="https://www.facebook.com/fpaindiaedupro/"
                          target="_blank"
                        >
                          <FaFacebookF />
                        </a>
                        <a
                          href="https://twitter.com/FPAINDIAEDUPRO?t=UB0LkhiMHbTHYlyv0a1jrA&s=09"
                          target="_blank"
                        >
                          <FaXTwitter />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/fpaindiaedupro/?originalSubdomain=in"
                          target="_blank"
                        >
                          <FaLinkedinIn />
                        </a>
                        <a
                          href="https://www.instagram.com/fpaindiaedupro/"
                          target="_blank"
                        >
                          <FaInstagram />
                        </a>
                        <a href="https://www.youtube.com/@fpaindiaedupro">
                          <FaYoutube />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div className="sticky-wrapper">
          <div className="sticky-active">
            <div className="menu-area">
              <div className="container">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <div className="header-logo">
                      <Link to="/">
                        <img src={Logo} alt="FPAINDIA" loading="lazy" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <nav className="main-menu d-none d-lg-inline-block">
                          <ul>
                            <li>
                              <Link to="/Recruiter">Recruiters</Link>
                            </li>
                            <li className="menu-item-has-children">
                              <Link to="/Courses">
                                Courses <FaAngleDown />
                              </Link>
                              <ul className="sub-menu">
                                {data.map((item) => (
                                  <li>
                                    <NavLink to={"/Course/" + item.slug}>
                                      <FaBook />
                                      {item.heading}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </li>
                            <li>
                              <Link to="/Faculty">Faculty</Link>
                            </li>
                            <li>
                              <Link to="/Gallery">Gallery</Link>
                            </li>
                            <li>
                              <Link to="/Blogs">Blogs</Link>
                            </li>
                            <li>
                              <Link to="/Testimonials">Testimonials</Link>
                            </li>
                            <li>
                              <Link to="/About-Us">About Us</Link>
                            </li>
                            <li>
                              <Link to="/Contact-Us">Contact Us</Link>
                            </li>
                          </ul>
                        </nav>
                        <button
                          onClick={handleClick}
                          type="button"
                          className="as-menu-toggle d-inline-block d-lg-none"
                        >
                          <FaBars />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
