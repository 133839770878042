import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import "./../App.css";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import "./landing.css";
import Logo from "../assets/img/logo/logo.png";

import img_2 from "../assets/img/log/call.gif";
import img_3 from "../assets/img/log/cta_2_shape1.png";
import img_4 from "../assets/img/log/cta_2_shape2.png";
import img_5 from "../assets/img/log/cta_2_shape3.png";

import Img_13 from "./../assets/img/about-11.jpg";
import Img_14 from "./../assets/img/about-12.jpg";

import Img_17 from "./../assets/img/landing/call.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaBook } from "react-icons/fa6";
import Recruiter from "../recruiter/Recruiter";
import { Helmet } from 'react-helmet';

const Landingpagetwo = () => {


    var Landingpagetwo = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
      };



 // console.log(props)
 const [isOpen, setIsOpen] = useState(false);
 const [isSubmitted, setIsSubmitted] = useState(false); // State to track if form is submitted
 const [isPopupOpened, setIsPopupOpened] = useState(false); // State to track if the popup has already been opened

 const [formData, setFormData] = useState({
   first_name: "",
   last_name: "",
   mobile: "",
   email: "",
   message: "",
   course_name: "",
 });

 const [data, setData] = useState([]);

 useEffect(() => {
   const fetchCourseData = async () => {
     try {
       const response = await axios.get("https://fpaindia.in/fpa_india_admin/public/api/courses");
       setData(response.data);
     } catch (error) {
       console.error("Error fetching data:", error);
     }
   };

   fetchCourseData();
 }, []);

 const handleChange = (e) => {
   setFormData({ ...formData, [e.target.name]: e.target.value });
 };

 const handleSubmit = async (e) => {
   e.preventDefault();

   try {
     const response = await axios.post(
       "https://fpaindia.in/fpa_india_admin/public/api/register",
       formData
     );

     // Add this part to submit data to the new API endpoint
     await axios.post(
       "https://api.neodove.com/integration/custom/4f49a6ac-dc16-41a8-abad-5fd2c4a0abb7/leads",
       {
         "name": formData.first_name + ' ' + formData.last_name,
         "mobile": formData.mobile,
         "email": formData.email,
         "massage": formData.message,
         "course": formData.course_name,
       }
     );

     // console.log(response.data);
     setIsSubmitted(true); // Set form submission state to true upon successful submission
   } catch (error) {
     console.error("Error submitting form:", error);
   }
 };

 const handleClose = () => {
   setIsOpen(false);
 };

 const openPopup = () => {
   setIsOpen(true);
 };

 useEffect(() => {
   const handleScroll = () => {
     if (
       !isPopupOpened &&
       window.innerHeight + window.scrollY >= document.body.offsetHeight
     ) {
       setIsOpen(true);
       setIsPopupOpened(true); // Set the flag to indicate that the popup has been opened
     }
   };

   window.addEventListener("scroll", handleScroll);

   return () => {
     window.removeEventListener("scroll", handleScroll);
   };
 }, [isPopupOpened]);

 useEffect(() => {
   if (isSubmitted) {
     alert("Form submitted successfully!");
     // Redirect logic after form submission
     window.location.href = "/Chartered-Wealth-Manager-Certification"; // Replace '/redirect-url' with the URL you want to redirect to
   }
 }, [isSubmitted]);

 return (
   <>
    <Helmet>
    <title>Chartered Wealth Manager Certification</title>
                <meta property="og:image" content='https://fpaindia.in/static/media/logo.32949fb4.png' />
                <meta property="og:description" content='CWM is the first wealth management course awarded by the American Academy Of financial planning and is globally recognized in more than 150+ countries.' />
                <meta name="keywords" content='Chartered, Wealth, Manager, Certification'></meta>
                <meta name="title" content='Chartered Wealth Manager Certification'></meta>
            </Helmet>
     <div>
       {isOpen && (
         <div className="popup">
           <div className="form_wrapper">
             <div className="form_container">
               <div className="title_container">
                 <h2>Request a Call Back</h2>
               </div>
               <div className="row clearfix">
                 <div className="">
                   <form
                     action=""
                     method="POST"
                     encType="multipart/form-data"
                     onSubmit={handleSubmit}
                   >
                     <div className="row clearfix">
                       <div className="col_half">
                         <div className="input_field">
                           <span>
                             <FaUser />
                           </span>
                           <input
                             type="text"
                             name="first_name"
                             placeholder="First Name"
                             value={formData.first_name}
                             onChange={handleChange}
                           />
                         </div>
                       </div>
                       <div className="col_half">
                         <div className="input_field">
                           <span>
                             <FaUser />
                           </span>
                           <input
                             type="text"
                             name="last_name"
                             placeholder="Last Name"
                             required=""
                             value={formData.last_name}
                             onChange={handleChange}
                           />
                         </div>
                       </div>
                     </div>
                     <div className="input_field">
                       <span>
                         <IoIosMail />
                       </span>
                       <input
                         type="email"
                         name="email"
                         placeholder="Email"
                         required=""
                         value={formData.email}
                         onChange={handleChange}
                       />
                     </div>
                     <div className="input_field">
                       <span>
                         <FaPhoneAlt />
                       </span>
                       <input
                         type="number"
                         name="mobile"
                         placeholder="Mobile Number"
                         required=""
                         value={formData.mobile}
                         onChange={handleChange}
                       />
                     </div>
                     <div className="input_field select_option">
                       <select
                         name="course_name"
                         value={formData.course_name}
                         onChange={handleChange}
                         required
                       >
                         <option>Select Course</option>
                         <option value="CWM (Chartered Wealth Manager)">CWM (Chartered Wealth Manager)</option>
                       </select>
                       <div className="select_arrow"></div>
                     </div>
                     <div className="input_field">
                       <textarea
                         type="text"
                         name="message"
                         placeholder="Description"
                         value={formData.massage}
                         onChange={handleChange}
                       ></textarea>
                     </div>
                     <div className="input_field">
                       <input
                         type="checkbox"
                         id="vehicle1"
                         name="vehicle1"
                         value="Bike"
                       />
                       <label style={{ textAlign: "left" }}>
                         I agree with terms and conditions
                       </label>
                     </div>
                     <button
                       className="as-btn Submit-button"
                       name="submit"
                       type="submit"
                     >
                       Submit
                     </button>
                   </form>
                 </div>
               </div>
             </div>
             <button
               className="buttonStyle close"
               id="model_close_button"
               onClick={handleClose}
             >
               <IoMdClose />
             </button>
           </div>
         </div>
       )}
     </div>
     <div className="landing_page_style">
       <nav className="navbar navbar-expand-lg navbar-dark" id="myHeader">
         <Container className="container px-4">
           <a className="navbar-brand">
             <img className="logoicfp" src={Logo} />
           </a>

           <div className=" " id="navbarResponsive">
             <ul className="navbar-nav ms-auto">
               <li className="nav-item callnowbtn">
                 <a className="nav-link" href="tel:7007662051">
                   <img src={img_2} /> <span> 7007662051</span>
                 </a>
               </li>
             </ul>
           </div>
         </Container>
       </nav>
       <div className="as-hero-wrapper border-normal">
         <Container>
           <Row>
             <Col sm={12}>
               <div
                 className="hero-slider-3 hero-slider-top as-carousel"
                 data-fade="true"
                 data-slide-show="1"
                 data-md-slide-show="1"
               >
                 <div className="as-hero-slide hero-1">
                   <div
                     className="as-hero-bg landing_bg_2"
                     data-overlay="overlay1"
                     data-opacity="1" style={{backgroundSize:'cover', backgroundRepeat:'no-repeat',}}
                   ></div>

                   <div className="container z-index-common">
                    <div>
                        <Row className="landing-page-top-hero-section">
                       <Col sm={8}>
                       <Slider {...Landingpagetwo}>
                        <div>
                         <div className="hero-style1 hero-slider-2 line-full">
                           <h1 className="hero-title">
                           CWM
                           </h1>
                           <h2 style={{color:'rgb(255, 255, 255)', textShadow:'#000000 2px 2px 4px'}}>Are You Ready For Global Career in Finance?</h2>
                           <br></br>
                           <p className="hero-text">
                           Take The First Step by Earning Global Acclaimed CWM Certification 
                           </p>
                           <a
                             className="as-btn Submit-button button_width_auto openPopup"
                             onClick={openPopup}
                           >
                             Enroll Now and Secure Your Financial Career!
                           </a>
                         </div>
                         </div>
                         <div>
                         <div className="hero-style1 hero-slider-2 line-full">
                           <h1 className="hero-title">
                           CWM
                           </h1>
                           <h2 style={{color:'rgb(255, 255, 255)', textShadow:'#000000 2px 2px 4px'}}>Are You Ready For Golbal Career in Finance?</h2>
                           <br></br>
                           <p className="hero-text">
                           Take The First Step by Earning Global Acclaimed CWM Certification
                           </p>
                           <a
                             className="as-btn Submit-button button_width_auto openPopup"
                             onClick={openPopup}
                           >
                             Enroll Now and Secure Your Financial Career!
                           </a>
                         </div>
                         </div>
                         </Slider>
                       </Col>
                       <Col sm={4}>
                       <div className="landing-page-top-form-bottom-margin">
                       <div className="form_wrapper">
             <div className="form_container">
               <div className="title_container">
                 <h2>Request a Call Back</h2>
               </div>
               <div className="row clearfix">
                 <div className="">
                   <form
                     action=""
                     method="POST"
                     encType="multipart/form-data"
                     onSubmit={handleSubmit}
                   >
                     <div className="row clearfix">
                       <div className="col_half">
                         <div className="input_field">
                           <span>
                             <FaUser />
                           </span>
                           <input
                             type="text"
                             name="first_name"
                             placeholder="First Name"
                             value={formData.first_name}
                             onChange={handleChange}
                           />
                         </div>
                       </div>
                       <div className="col_half">
                         <div className="input_field">
                           <span>
                             <FaUser />
                           </span>
                           <input
                             type="text"
                             name="last_name"
                             placeholder="Last Name"
                             required=""
                             value={formData.last_name}
                             onChange={handleChange}
                           />
                         </div>
                       </div>
                     </div>
                     <div className="input_field">
                       <span>
                         <IoIosMail />
                       </span>
                       <input
                         type="email"
                         name="email"
                         placeholder="Email"
                         required=""
                         value={formData.email}
                         onChange={handleChange}
                       />
                     </div>
                     <div className="input_field">
                       <span>
                         <FaPhoneAlt />
                       </span>
                       <input
                         type="number"
                         name="mobile"
                         placeholder="Mobile Number"
                         required=""
                         value={formData.mobile}
                         onChange={handleChange}
                       />
                     </div>
                     <div className="input_field select_option">
                       <select
                         name="course_name"
                         value={formData.course_name}
                         onChange={handleChange}
                         required
                       >
                         <option>Select Course</option>
                         <option value="CWM (Chartered Wealth Manager)">CWM (Chartered Wealth Manager)</option>
                       </select>
                       <div className="select_arrow"></div>
                     </div>
                     <div className="input_field">
                       <textarea
                         type="text"
                         name="message"
                         placeholder="Description"
                         value={formData.massage}
                         onChange={handleChange}
                       ></textarea>
                     </div>
                     <div className="input_field">
                       <input
                         type="checkbox"
                         id="vehicle1"
                         name="vehicle1"
                         value="Bike"
                       />
                       <label style={{ textAlign: "left" }}>
                         I agree with terms and conditions
                       </label>
                     </div>
                     <button
                       className="as-btn Submit-button"
                       name="submit"
                       type="submit"
                     >
                       Submit
                     </button>
                   </form>
                 </div>
               </div>
             </div>
             <button
               className="buttonStyle close"
               id="model_close_button"
               onClick={handleClose}
             >
               <IoMdClose />
             </button>
           </div>
                         </div>
                       </Col>
                       </Row>
                       </div>
                   </div>
                  
                 </div>
               </div>
             </Col>
           </Row>
         </Container>
       </div>
       <section>
         <div className="space overflow-hidden" id="about-sec">
           <div className="container">
             <div
               className="row align-items-center"
               style={{ justifyContent: "space-between" }}
             >
               <div className="col-xl-6 width_45_box">
                 <div className="img-box1 mb-40 mb-xl-0 shape-mockup-wrap">
                   <div className="img1">
                     <img className="tilt-active" src={Img_13} alt="About" />
                   </div>
                   <div className="img2">
                     <img className="tilt-active" src={Img_14} alt="About" style={{height:'200px'}}/>
                   </div>
                 </div>
               </div>
               <div className="col-xl-6">
                 <div className="title-area mb-30">
                   <span className="sub-title sub-title-left">
                     <FaBook/> OVER 3,00,000 CERTIFICATION HOLDERS IN 150+ COUNTRIES.
                   </span>
                   <h2 className="sec-title">
                   Connect Your Career with the World...
                   </h2>
                 </div>
                 <p className="mt-n2 mb-25">
                  <ul>
                    <li>CWM Charter Holders Earn Astounding 54% more than their Peers</li>
                    <li>CWM Certification is directly awarded from AAFM USA and is valid in more than 150 Countries.</li>
                    <li>CWM Certification Presents Opportunities Worldwide.</li>
                    <li>Top Global Banks Employ CWM Charter holders.</li>
                    <li>CWM Certification is approved under SEBI RIA Regulations 2013.</li>
                  </ul>
                 </p>
                 <div className="btn-group mt-40 openPopup" style={{justifyContent:'center', width:'100%'}}>
                   <a
                     
                     className="as-btn"
                     onClick={openPopup}
                   >
                     Become a Certified Financial Planner Now!
                     <i className="fa-regular fa-arrow-right ms-2"></i>
                   </a>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>

       <section className="counter">
         <div className="container">
           <div className="counter_top_content">
             <div className="row">
               <div className="col-sm-6 col-xl-4">
                 <div className="counter_top_content_h2">
                   <p>
                     <strong>100%</strong>Passing Rate
                   </p>
                 </div>
               </div>
               <div className="col-sm-6 col-xl-4">
                 <div className="counter_top_content_h2">
                   <p>
                     <strong>100%</strong> Placements
                   </p>
                 </div>
               </div>
               <div className="col-sm-6 col-xl-4">
                 <div className="counter_top_content_h2">
                   <p>
                     <strong>1500+</strong>Batches Completed
                   </p>
                 </div>
               </div>
             </div>
           </div>
           <div className="max-width_1366">
             <div className="row justify-content-between box_counter_box">
               <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                 <div className="counter-card landing-page-counter-card">
                   <h2 className="counter-card_number">
                     <span className="counter-number">50,000</span>
                     <span className="fw-normal">+</span>
                   </h2>
                   <p className="counter-card_text">Placements</p>
                 </div>
               </div>
               <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                 <div className="counter-card landing-page-counter-card">
                   <h2 className="counter-card_number">
                     <span className="counter-number">5 - 8</span>
                     <span className="fw-normal">Lakh</span>
                   </h2>
                   <p className="counter-card_text">Avg Salary</p>
                 </div>
               </div>
               <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                 <div className="counter-card landing-page-counter-card">
                   <h2 className="counter-card_number">
                     <span className="counter-number">60</span>
                     <span className="fw-normal">%</span>
                   </h2>
                   <p className="counter-card_text">Salary Hike</p>
                 </div>
               </div>
               <div className="col-sm-6 col-xl-3 counter-card-wrap counter_box_color">
                 <div className="counter-card landing-page-counter-card">
                   <h2 className="counter-card_number">
                     <span className="counter-number">600</span>
                     <span className="fw-normal">+</span>
                   </h2>
                   <p className="counter-card_text">Hiring Companies</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>

       <section className="space-normal landing-2-page">
         <Container>
           <Row className="align-items-center mt-60">
             <Col sm={6}>
               <div className="moto-box moto-box-img-landing">
                 <div className="moto-box_img checklist">
                   <h4 style={{textAlign:'center'}}>Benefits Of CWM®</h4>
                   <ul>
                                                    <li>Use of Board Certified Designations after your name on your Business Card and on your Promotional Materials.</li>
                                                    <li>Global Certification in true sense and no territorial examination required.</li>
                                                    <li>CWM® is awarded directly by American Academy of Financial Management, US.</li>
                                                    <li>Protect your job and be recognized by an independent and global authority.</li>
                                                    <li>The AAFM Credentials and Board Certifications are for those who want to rise above the rest.</li>
                                                    <li>You can add AAFM to your Resume, Degree or Professional Licenses &amp; Improve your image and salary potential.</li>
                                                    <li>Publish on our site as a Certified Member.</li>
                                                    <li>Be part of Large Members Network of 50,000+.</li>
                                                    <li>Members in more than 100 Countries with Global AACSB Alliance.</li>
                                                </ul>
                 </div>
                 <div className="openpopup-width">
                 <a
                   className="as-btn Submit-button button_width_auto openPopup"
                   
                   onClick={openPopup}
                   id="openPopup"
                 >
                   Enroll Now!
                 </a>
                 </div>
               </div>
             </Col>
             <Col sm={6}>
             <div className="moto-box moto-box-img-landing">
                 <div className="moto-box_img checklist">
                   <h4 style={{textAlign:'center'}}>The Program Helps In</h4>
                   <ul>
                                                    <li>Acquiring core understandings in and across the disciplines that contribute to the domain of Wealth Management including Wealth Creation, Wealth Enhancement, Wealth Preservation and Wealth Transfer.</li>
                                                    <li>Relating these conceptual frameworks to dimensions of practical areas on the ground.</li>
                                                    <li>Providing candidates with opportunities to encounter and critically engage with instances of both challenging problems and attempted interventions by a Wealth Manager.</li>
                                                    <li>Engage with the complex financial markets, human attitudes and investment life cycles.</li>
                                                    <li>Explore the ethical and personal dimensions of Wealth Advisory and understand the fiduciary responsibilities of a Wealth Manager.</li>
                                                </ul>
                 </div>
                 <div className="openpopup-width">
                 <a
                   className="as-btn Submit-button button_width_auto openPopup"
                   
                   onClick={openPopup}
                   id="openPopup"
                 >
                   Enroll Now!
                 </a>
                 </div>
               </div>
             </Col>
           </Row>
         </Container>
       </section>
       <section className="position-relative overflow-hidden space shape-mockup-wrap">
         <div className="cta-bg-img background-image landing_bg_2"></div>
         <div className="cta-bg-img2 background-image landing_bg_3"></div>
         <div
           className="shape-mockup cta-shape1 jump d-md-block d-none"
           style={{ bottom: "-7%", left: "-2%" }}
         >
           <img src={img_3} />
         </div>
         <div
           className="shape-mockup cta-shape2 jump-reverse d-md-block d-none"
           style={{ top: "-3%", right: "-1%" }}
         >
           <img src={img_4} />
         </div>
         <div
           className="shape-mockup cta-shape3 spin d-md-block d-none"
           style={{ top: "30%", right: "20%" }}
         >
           <img src={img_5} alt="img" />
         </div>
         <div className="container text-center">
           <div className="cta-wrap2">
             <div className="title-area text-center mb-35">
               <span className="sub-title white" style={{justifyContent:'center'}}>
                 <FaBook style={{marginRight:10}}/>FPA INDIA EDUPRO
               </span>
               <h2 className="sec-title text-white">
               CONNECT YOUR CAREER WITH THE WORLD...
               </h2>
               <p
                 className="cta-text white"
                 style={{ maxWidth: "767px", margin: "auto", width: "100%" }}
               >
                American Academy of Financial Management® is a Global Standard Setting Body with the objective to promote Wealth Management and Financial Advisory Standards globally. AAFM, USA is a 16 years old organization working globally having offices in 17 countries and members presence in 151+ Countries.
               </p>
             </div>
           </div>
           <a
             className="as-btn Submit-button button_width_auto openPopup"
             
             id="openPopup"
             onClick={openPopup}
           >
             Start Your CWM Journey Today!
           </a>
         </div>
       </section>

<Recruiter />

       <footer className="py-5 footer_landing_page">
         <h5 className="text-center">
           FPA INDIA EDUPRO EDUPRO PVT LTD
           <br></br>Shagun Palace, First Floor, Sapru Marg, Hazratganj, Lucknow
           <br></br>( American Academy of Financial Management )
           <br></br>
         </h5>
       </footer>
       <div id="whatsapp_1">
   <div className="whatsapp_2">
       <div className="whatsapp_3">
           <a href="https://wa.me/7007662051" target="_blank" aria-label="Go to whatsapp" direction="column" order="whatsapp" className="whatsapp_4">
           <svg
   viewBox="0 0 32 32"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   style={{
     width: "100%",
     height: "100%",
     fill: "rgb(255, 255, 255)",
     stroke: "none",
   }}

 >
   <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
 </svg>
           </a>
       </div>
   </div>
   <a size="50" type="link"
       href="https://getbutton.io/?utm_campaign=multy_widget&amp;utm_medium=widget&amp;utm_source=www.nsim.in"
       target="_blank" aria-label="Go to GetButton.io website" className="sc-sbsi7l-0 cLcbjv whatsapp_6">
   </a>
</div>
<div id="whatsapp_1" style={{bottom:'80px'}}>
   <div className="whatsapp_2">
       <div className="whatsapp_3">
           <a direction="column" order="whatsapp" className="whatsapp_4" >
             <img src={Img_17}/>
           </a>
       </div>
   </div>
   <a href="https://getbutton.io/?utm_campaign=multy_widget&amp;utm_medium=widget&amp;utm_source=www.nsim.in" target="_blank" aria-label="Go to GetButton.io website" className="sc-sbsi7l-0 cLcbjv">
   </a>
</div>
     </div>
   </>
 );
};

export default Landingpagetwo