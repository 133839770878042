import React from 'react';
import Image from '../components/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { IoTime } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa6";
import img_1 from '../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from "react-router-dom";
import Getintouch from '../form/Getintouch';


const Contact = () => {
  return (
    <>
    <Header/>
    <div className="content_page_bg breadcumb-wrapper background-image shape-mockup-wrap" data-overlay="title" data-opacity="8" style={{height:'460px'}}>
    <div className="breadcumb-shape background-image"></div>
    <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
        <img src={img_1} alt="shape"/></div>
    <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
        <img src={img_2} alt="shape"/></div>
        <Container style={{height:'100%'}}>
            <Row style={{height:'100%'}}>
                <Col sm={12}>
                <div className="breadcumb-content text-center">
            <h1 className="breadcumb-title">Contact Us</h1>
            <ul className="breadcumb-menu">
                <li><NavLink  to="/">Home</NavLink></li>
                <li><FaChevronRight /></li>
                <li>Contact Us</li>
            </ul>
        </div>
                </Col>
            </Row>
        </Container>
</div>
<div className="space" id="contact-sec">
    <Container>
    <div className="map-sec">
            <div className="map"><iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.546436245593!2d80.99138257548822!3d26.854374862594995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be2ce8d4448b1%3A0xf922104953e3e835!2s4%2C%20Vijay%20Khand%2C%20Ujariyaon%2C%20Vivek%20Khand%204%2C%20Gomti%20Nagar%2C%20Lucknow%2C%20Uttar%20Pradesh%20226010!5e0!3m2!1sen!2sin!4v1711244820186!5m2!1sen!2sin"
                    allowfullscreen="" loading="lazy"></iframe></div>
        </div>
        <Row>
            <Col sm={5} className='mb-30 mb-xl-0'>
            <div className="me-xxl-5 mt-60">
                    <div className="title-area mb-25">
                        <h2 className="border-title h3">Have Any Questions?</h2>
                    </div>
                    <p className="mt-n2 mb-25">Have a inquiry or some feedback for us? Fill out the form below to contact our team. please contact us at support@fpaindia.in</p>
                    <div className="contact-feature">
                        <div className="contact-feature-icon"><FaLocationDot /></div>
                        <div className="media-body">
                            <p className="contact-feature_label">Our Address</p><a  to="https://www.google.com/maps"
                                className="contact-feature_link">4/338 Vivek Khand 4, Gomti Nagar, Lucknow</a>
                        </div>
                    </div>
                    <div className="contact-feature">
                        <div className="contact-feature-icon"><FaPhone /></div>
                        <div className="media-body">
                            <p className="contact-feature_label">Phone Number</p><a  to="tel:+011456586986"
                                className="contact-feature_link">Mobile:<span> (+91) 8318 061 647</span></a> <a
                                 to="tel:+011456586986" className="contact-feature_link">Phone: <span> (+91) 7007 662 051</span></a>
                        </div>
                    </div>
                    <div className="contact-feature">
                        <div className="contact-feature-icon"><IoTime /></div>
                        <div className="media-body">
                            <p className="contact-feature_label">Hours of Operation</p><span
                                className="contact-feature_link">Monday - Friday: 09:00 - 20:00</span> <span
                                className="contact-feature_link">Sunday &amp; Saturday: 10:30 - 22:00</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={7}>
           <Getintouch/>
            </Col>
        </Row>
    </Container>
</div>
    <Footer/>
    </>
  )
}

export default Contact