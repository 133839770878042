import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../header/header';
import Footer from '../footer/Footer';
import img_1 from '../../assets/img/breadcumb_shape_1_2.png';
import img_2 from '../../assets/img/breadcumb_shape_1_3.png';
import { NavLink } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';

const Cookie = () => {
  return (
    <>
    <Header />

<div
  className="Cookie_page_bg breadcumb-wrapper background-image shape-mockup-wrap"
  data-overlay="title"
  data-opacity="8"
  style={{ height: '460px' }}>
  <div className="breadcumb-shape background-image"></div>
  <div className="shape-mockup breadcumb-shape2 jump d-lg-block d-none">
    <img
      src={img_1}
      alt="shape"
    />
  </div>
  <div className="shape-mockup breadcumb-shape3 jump-reverse d-lg-block d-none">
    <img
      src={img_2}
      alt="shape"
    />
  </div>
  <Container style={{ height: '100%' }}>
    <Row style={{ height: '100%' }}>
      <Col sm={12}>
        <div className="breadcumb-content text-center">
          <h1 className="breadcumb-title">FPA INDIA Cookie Policy</h1>
          <ul className="breadcumb-menu">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <FaChevronRight />
            </li>
            <li>FPA INDIA Cookie Policy</li>
          </ul>
        </div>
      </Col>
    </Row>
  </Container>
</div>
<div className="space">
    <Container>
        <Row>
            <Col sm={12}>
            <div className="main-content-abot-page">
                    <h4>FPA INDIA Cookie Policy</h4>
                    <span>Last Updated: January 2023</span>
                    <p>
                        This Cookie Policy applies to any websites, branded pages on third party platforms (such as Facebook or YouTube), and applications accessed or used through such websites or third party platforms (“FPA INDIA websites”) which are operated by or on behalf of FPA INDIA.
                    </p>
                    <p>
                        By using FPA INDIA websites, you are consenting to our use of cookies in accordance with this Cookie Policy. If you do not agree to our use of cookies in this way, you should set your browser settings accordingly or not use FPA INDIA websites. If you disable the cookies that we use, this may impact your user experience while on FPA INDIA websites.
                    </p>
                    <p>
                        The table below summarizes the different types of cookie we use on FPA INDIA websites, together with their respective purpose and duration (i.e. how long each cookie will remain on your device).
                    </p>
                    <h4>What are cookies?</h4>
                    <p>
                        Cookies are files or pieces of information that may be stored on your computer (or other internet enabled devices, such as a smartphone or tablet) when you visit an FPA INDIA website. A cookie will usually contain the name of the website from which the cookie has come from, the "lifetime" of the cookie (i.e. how long it will remain on your device), and a value, which is usually a randomly generated unique number.
                    </p>
                    <h4>What do we use cookies for?</h4>
                    <p>
                    We use cookies to make the FPA INDIA websites easier to use and to better tailor the FPA INDIA websites and our services to your interests and needs. Cookies may also be used to help speed up your future activities and experience on our Sites. We also use cookies to compile anonymous, aggregated statistics that allow us to understand how people use our Sites and to help us improve their structure and content. We cannot identify you personally from this information.
                    </p>
                   <h4>What types of cookies do we use?</h4>
                   <p>
                       Two types of cookies may be used on the FPA INDIA websites - "session cookies" and "persistent cookies". Session cookies are temporary cookies that remain on your device until you leave the FPA INDIA website. A persistent cookie remains on your device for much longer or until you manually delete it (how long the cookie remains on your device will depend on the duration or "lifetime" of the specific cookie and your browser settings).
                   </p>
                   <p>
                       Some of the pages you visit may also collect information using pixel tags (also called clear gifs) that may be shared with third parties which directly support our promotional activities and website development. For example, website usage information about visitors to FPA INDIA websites may be shared with our third party advertising agency to better target Internet banner advertisements on our websites. The information is not, however, personally identifiable, although it could be linked to your personal information.
                   </p>
                   <h4>How can I control or delete cookies?</h4>
                   <p>
                       Most internet browsers are initially set up to automatically accept cookies. You can change the settings to block cookies or to alert you when cookies are being sent to your device. There are a number of ways to manage cookies. Please refer to your browser’s instructions or help screen to learn more about how to adjust or modify your browser settings.
                   </p>
                   <p>
                       If you disable the cookies that we use, this may impact your experience while on FPA INDIA websites, for example you may not be able to visit certain areas of an FPA INDIA website or you may not receive personalized information when you visit an FPA INDIA website.
                   </p>
                   <p>
                       If you use different devices to view and access FPA INDIA websites (e.g. your computer, smartphone, tablet, etc.) you will need to ensure that each browser on each device is adjusted to suit your cookie preferences.
                   </p>
                </div>
            </Col>
        </Row>
    </Container>
</div>
<Footer/>
    </>
  )
}

export default Cookie