import React, { useEffect, useState } from 'react';
import Header from '../layout/header/header';
import Footer from '../layout/footer/Footer';
import Image from '../components/Image';
import logo from '../assets/img/logo/logo.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaClock } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import Serverimage from '../components/Serverimage';

// icon
import { FaLocationDot } from "react-icons/fa6";

const Event = () => {
    const [data, setData] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://fpaindia.in/fpa_india_admin/public/api/event');
        setData(response.data); // Assuming the API response is in JSON format
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function
    fetchData();
  }, []); // Empty
  return (
   <>
   <Header/>
   <div className="breadcumb-wrapper Event_page_bg" style={{height:'460px'}}>
    <Container style={{height:'100%'}}>
        <Row style={{height:'100%'}}>
            <Col sm={12}>
            <div className="container z-index-common breadcumb-text-center">
                    <h1 className="breadcumb-title">Events</h1>
                </div>
            </Col>
        </Row>
    </Container>
</div>
    <section className="space-top space-extra-bottom">
        <Container className="z-index-common">
            <Row>
            {data.map((item) => (
                <Col sm={6}>
                <div className="event-card">
                        <div className="event-card_img"><Serverimage text={item.user_image} /></div>
                        <div className="event-card_content">
                            <div className="event-meta">
                                <p><FaLocationDot />  Marg, Lucknow (U.P.), India Pin - 226004</p>
                                {/* <p><FaClock />12:00:00 - 01:00:00</p> */}
                            </div>
                            <h3 className="event-card_title"><NavLink  to={'/Event/' + item.slug}>{item.heading}</NavLink></h3>
                            <div className="event-card_bottom"><NavLink  to={'/Event/' + item.slug} className="as-btn">View Event</NavLink>
                                <div className="event-author">
                                    <div className="avater"><img src={logo}/></div><span
                                        className="author-name">FPA INDIA</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                ))}
            </Row>
        </Container>
    </section>
   <Footer/>
   </>
  )
}

export default Event