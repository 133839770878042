import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import axios from 'axios';
import Image from "../components/Image";
import Serverimage from '../components/Serverimage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Homecontent = () => {


  var Blogs = {
    dots: false, // Dots hide kare
    arrows: false, // Arrows hide kare
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true, // Autoplay enable kare
    autoplaySpeed: 100,
    speed: 3000, // Slider speed set kare
    pauseOnHover: true, // Hover pe pause disable kare
    draggable: true, // Dragging disable kare
    swipe: true, // Swipe disable kare
    touchMove: true, // Touch move disable kare
  };
  var Events = {
    dots: false, // Dots hide kare
    arrows: false, // Arrows hide kare
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true, // Autoplay enable kare
    autoplaySpeed: 100,
    speed: 3000, // Slider speed set kare
    pauseOnHover: true, // Hover pe pause disable kare
    draggable: true, // Dragging disable kare
    swipe: true, // Swipe disable kare
    touchMove: true, // Touch move disable kare
  };


  const [blogData, setBlogData] = useState([]);
  const [eventData, setEventData] = useState([]);


 

  useEffect(() => {
    // Function to fetch blog data from the API
    const fetchBlogData = async () => {
      try {
        const response = await axios.get('https://fpaindia.in/fpa_india_admin/public/api/blog');
        setBlogData(response.data); // Assuming the API response is in JSON format
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    // Call the function to fetch blog data
    fetchBlogData();
  }, []); // Empty dependency array means this effect will only run once, after the initial render

  useEffect(() => {
    // Function to fetch event data from the API
    const fetchEventData = async () => {
      try {
        const response = await axios.get('https://fpaindia.in/fpa_india_admin/public/api/event');
        setEventData(response.data); // Assuming the API response is in JSON format
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    // Call the function to fetch event data
    fetchEventData();
  }, []); // Empty dependency array means this effect will only run once, after the initial render

 

  return (
    <section className="space-normal">
      <Container>
        <Row>
          <Col sm={4}>
            <div className="event-card-main event-card-main-3">
              <h2 className="sec-title">Blogs</h2>
              <div className="upcoming-events-main">
                  <Slider {...Blogs}>
                   {blogData.map((item) => (
                    <div className="marquee">
                  <div className="event-card event-card-index-page" key={item.id}>
                    <div className="event-card_img">
                      <Serverimage text={item.user_image} />
                    </div>
                    <div className="event-card_content">
                      <div className="event-meta blog-meta-slider">
                        <p>
                          <i className="fal fa-clock">{item.blog_date}</i>
                        </p>
                      </div>
                      <h3 className="event-card_title">
                        <Link to={'/Blog/' + item.slug}>
                          {item.heading}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  </div>
                ))}
                
                  </Slider>
                <div className="Courses-button text-center mt-20 mt-xl-5">
                  <Link to="/Blogs" className="as-btn">
                    View All Blogs<i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="event-card-main event-card-main-3">
              <h2 className="sec-title">Gallery</h2>
              <div className="upcoming-events-main">
                <div className="marquee-main">
                  <div className="marquee">
                    <div className="marquee__group">
                      <Image text="CFA" />
                    </div>
                  </div>
                  <div className="marquee marquee--reverse">
                    <div className="marquee__group">
                      <Image text="CFA" />
                    </div>
                  </div>
                </div>
                <div className="Courses-button text-center mt-20 mt-xl-5">
                  <Link to="/Gallery" className="as-btn">
                    View All Photo's<i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="event-card-main event-card-main-3">
              <h2 className="sec-title">Upcoming Events</h2>
              <div className="upcoming-events-main">
              <Slider {...Events}>
                 {eventData.map((item) => (
                   <div className="marquee">
                  <div className="event-card event-card-index-page" key={item.id}>
                    <div className="event-card_img">
                      <Serverimage text={item.user_image} />
                    </div>
                    <div className="event-card_content">
                      <div className="event-meta blog-meta-slider">
                        <p>
                          <i className="fal fa-clock">{item.event_date}</i>
                        </p>
                      </div>
                      <h3 className="event-card_title">
                      <Link to={'/Event/' + item.slug}>
                          {item.heading}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  </div>
                ))}
                </Slider>
                <div className="Courses-button text-center mt-20 mt-xl-5">
                  <Link to="Event/" className="as-btn">
                    View All Events<i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Homecontent;
