import React, { useEffect, useState } from 'react';
import Carousel from "react-bootstrap/Carousel";
import Image from "../components/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
// icon
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Serverimage from '../components/Serverimage';

const Hero = () => {


  const [data, setData] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://fpaindia.in/fpa_india_admin/public/api/courses');
        setData(response.data); // Assuming the API response is in JSON format
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function
    fetchData();
  }, []); // Empty

  return (
    <div className="as-hero-wrapper border-normal">
      <Container>
        <Row>
          <Col sm={10} className="col-md-10_5">
            <Carousel controls={false}>
            {data.map((item) => (
              <Carousel.Item className='heroslider-style'>
                <Serverimage text={item.bg_image} />
                <Carousel.Caption>
                  <div className="hero-style1 hero-slider-2">
                    <h1 className="hero-title">{item.heading}</h1>
                    <p className="hero-text width_9">
                    {item.short_description}
                    </p>
                    <NavLink to={'/Course/' + item.slug} className="as-btn style3">
                    View More
                      <FaArrowRight />
                      </NavLink>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col sm={2} className=" col-md-2_5 mobile-view-none">
            <div className="Social-Media">
              <h2 className="sec-title">Social Media</h2>
              <div className="Social-Media-icon">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/fpaindiaedupro/"
                      target="_blank"
                    >
                      <span>
                        <FaFacebookF />
                      </span>
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/FPAINDIAEDUPRO?t=UB0LkhiMHbTHYlyv0a1jrA&s=09"
                      target="_blank"
                    >
                      <span>
                        <FaXTwitter />
                      </span>
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/fpaindiaedupro/?originalSubdomain=in"
                      target="_blank"
                    >
                      <span>
                        <FaLinkedinIn />
                      </span>
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/fpaindiaedupro/"
                      target="_blank"
                    >
                      <span>
                        <FaInstagram />
                      </span>
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@fpaindiaedupro"
                      target="_blank"
                    >
                      <span>
                        <FaYoutube />
                      </span>
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
