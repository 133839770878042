import React from 'react';
import Logo from '../../assets/img/logo/logo.png';
import footer_1 from '../../assets/img/footer/footer_shape_1.png';
import footer_2 from '../../assets/img/footer/footer_shape_2.png';
import footer_3 from '../../assets/img/footer/footer_shape_3.png';
// icon
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-wrapper footer-layout1">
    <div className="widget-area">
        <div className="container">
            <div className="row justify-content-between padding-normal">
                <div className="col-md-6 col-xxl-3 col-xl-4">
                    <div className="widget footer-widget">
                        <div className="as-widget-about">
                            <div className="about-logo"><Link to="/"><img src={Logo} alt="FPAINDIA" loading="lazy"/></Link></div>
                            <p className="about-text">FPA INDIA EDUPRO PVT. LTD.</p>
                            <h4 className="footer-info-title">Follow Us On:</h4>
                            <div className="as-social">
                                <a href="https://www.facebook.com/fpaindiaedupro/"><FaFacebookF /></a> 
                                        <a href="https://twitter.com/FPAINDIAEDUPRO?t=UB0LkhiMHbTHYlyv0a1jrA&s=09" target="_blank"><FaXTwitter /></a> 
                                        <a href="https://www.linkedin.com/company/fpaindiaedupro/?originalSubdomain=in" target="_blank"><FaLinkedinIn /></a> 
                                        <a href="https://www.instagram.com/fpaindiaedupro/" target="_blank"><FaInstagram /></a> 
                                        <a href="https://www.youtube.com/@fpaindiaedupro" target="_blank"><FaYoutube /></a></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-auto">
                    <div className="widget widget_nav_menu footer-widget">
                        <h3 className="widget_title">Quick link</h3>
                        <div className="menu-all-pages-container">
                            <ul className="menu">
                                <li><Link to="/Recruiter"><GoDotFill />Recruters</Link></li>
                                <li><Link to="/Faculty"><GoDotFill />Faculty</Link></li>
                                <li><Link to="/Courses"><GoDotFill />Courses</Link></li>
                                <li><Link to="/Blogs"> <GoDotFill />Blog</Link></li>
                                <li><Link to="/Gallery"><GoDotFill />Gallery</Link></li>
                                <li><Link to="/Events"><GoDotFill />Events</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-auto">
                    <div className="widget widget_nav_menu footer-widget">
                        <h3 className="widget_title">Resources</h3>
                        <div className="menu-all-pages-container">
                            <ul className="menu">
                                <li><Link to="/Cookie"><GoDotFill />Cookie Policy</Link></li>
                                <li><Link to="/Privacy-Policy"><GoDotFill />Privacy Policy</Link></li>
                                <li><Link to="/Terms-And-Conditions"><GoDotFill />Terms and Conditions</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="widget footer-widget">
                        <h3 className="widget_title">Get in touch!</h3>
                        <form className="newsletter-widget">
                            <p className="footer-text">Subscribe to get the latest updates from the world of Finance</p>
                            <div className="form-group"><input className="form-control" type="email" placeholder="Enter Email"
                                    required=""/> <i className="fal fa-envelope"></i></div>
                            <button type="submit" className="as-btn shadow-none">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="copyright-wrap">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                    <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2024 <Link to="/">FPA INDIA</Link>.
                        All Rights Reserved.</p>
                </div>
                <div className="col-lg-6 text-end d-none d-lg-block">
                    <div className="footer-links">
                        <ul>
                        <li><Link to="/Cookie">Cookie Policy</Link></li>
                                <li><Link to="/Privacy-Policy">Privacy Policy</Link></li>
                                <li><Link to="/Terms-And-Conditions">Terms and Conditions</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="shape-mockup jump d-none d-xl-block" style={{top:'24%', left:'4%'}}><img src={footer_1} alt="shapes"loading="lazy"/></div>
    <div className="shape-mockup jump-reverse d-none d-xl-block" style={{bottom:'20%', right:'4%'}}><img src={footer_2} alt="shapes"loading="lazy"/></div>
    <div className="shape-mockup" style={{top:'0', right:'0'}} data-top="0" data-right="0"><img src={footer_3} alt="shapes"loading="lazy"/>
    </div>
</footer>
  )
}

export default Footer